<template>
  <div class="py-3">
    <!-- <section id="section-trans" class="mb-5">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center">
            <div class="col-md-8 mx-auto text-center white--text">
              <p class=" text-h3"> Pencarian Program</p>
              <v-card class="pa-3">                              
                <div class="row align-center">
                  <div class="col-md-10">
                    <v-spacer class="py-3"></v-spacer>
                    <v-text-field
                      placeholder="Cari program yang anda inginkan"
                      outlined
                      prepend-inner-icon="ri-search-line"
                      class="rounded-lg"
                    ></v-text-field>
                  </div>
                  <div class="col-md-2">
                    <v-btn
                      depressed                      
                      color="primary"
                      class="font-weight-bold"
                      block
                    >
                      Cari
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </div>            
          </div>
      </div>      
    </section> -->
    <section id="kategori" class="py-5">
      <div class="col-md-10 mx-auto">
        <div class="row">
          <div class="col-md-3">
            <h2 class="mb-1">Pencarian Kelas</h2>
            <p class="color-disable">Menampilkan {{ total_program }} kelas</p>
            <v-card class="rounded-lg pa-3 d-none d-lg-block" elevation="2">
              <div class="box-category mb-2">
                <h3 class="mb-3">Jenis Kelas</h3>
                <v-select
                  outlined
                  class="rounded-lg"
                  placeholder="Pilih jenis kelas"
                  v-model="params.class_type"
                  item-value="id"
                  item-text="name"
                  :items="type_class"
                >
                </v-select>
              </div>
              <div class="box-category mb-2">
                <h3 class="mb-3">Bidang Studi</h3>
                <v-autocomplete
                  outlined
                  class="rounded-lg"
                  placeholder="Pilih bidang studi"
                  multiple
                  chips
                  small-chips
                  v-model="studi_id"
                  item-value="id"
                  item-text="title"
                  :items="list_studi">
                </v-autocomplete>
                <!-- <v-checkbox
                  v-for="(item, index) in list_studi" :key="index"
                  v-model="studi_id"
                  :label="item.title"
                  :value="item.id"
                  @click="getList()"
                  hide-details
                  class="mt-1"
                  multiple
                  ></v-checkbox>
                  <v-btn v-if="list_studi.length <= 10" color="primary" class="font-weight-bold mt-2" @click="getStudi(limit='-1')" text block>Tampilkan semua <v-icon>ri-arrow-down-s-line</v-icon></v-btn>
                  <v-btn v-else color="primary" class="font-weight-bold mt-2" @click="getStudi(limit='8')" text block>Sembunyikan <v-icon>ri-arrow-up-s-line</v-icon></v-btn> -->
              </div>
              <div class="box-category mb-3">
                <h3 class="">Tipe Kelas</h3>
                <v-radio-group v-model="params.is_free" class="mt-2">
                  <v-radio
                    v-for="(item, index) in list_progs"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                    hide-details
                  ></v-radio>
                </v-radio-group>
              </div>
              <div class="box-category mb-5">
                <!-- <p class="fs-18 font-weight-medium mb-1">Bidang Studi</p> -->
                <h3 class="mb-3">Harga</h3>
                <v-text-field
                  v-model="price_start"
                  placeholder="Harga terendah"
                  outlined
                  prefix="Rp"
                  class="rounded-lg mb-3"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  v-model="price_end"
                  placeholder="Harga tertinggi"
                  outlined
                  prefix="Rp"
                  class="rounded-lg"
                  hide-details="auto"
                ></v-text-field>
              </div>
              <div class="box-button mb-2">
                <div class="row">
                  <div class="col-6 px-2">
                    <v-btn
                      outlined
                      color="primary"
                      class="rounded-xl font-weight-normal"
                      block
                      small
                      @click="resetList"
                    >
                      Reset Filter
                    </v-btn>
                  </div>
                  <div class="col-6 px-2">
                    <v-btn
                      depressed
                      color="primary"
                      class="rounded-xl font-weight-normal"
                      block
                      small
                      @click="filterList"
                    >
                      Terapkan
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <v-btn
            class="filter-search d-lg-none d-md-block primary--text bg-white rounded-xl"
            @click="filter = !filter"
          >
            Filter <v-icon class="ms-3">ri-filter-3-fill</v-icon>
          </v-btn>
          <div class="col-md-9">
            <!-- <h2 class="mb-3">Hasil Pencarian</h2> -->
            <v-text-field
              v-model="params.q"
              placeholder="Cari program yang anda inginkan"
              solo
              append-icon="ri-search-line"
              class="rounded-xl mb-1 box-search"
              @keydown.enter="getList"
            ></v-text-field>
            <div class="row mb-5">
              <div v-if="total_program === 0" class="col-md-12 pt-12">
                <v-col md="6" class="text-center mx-auto">
                  <div class="text-center" align-content="center">
                    <!-- <v-col> -->
                    <img src="@/assets/images/data_empty.png" class="mb-3" />
                    <div class="title text-center color-disable">
                      Program tidak ditemukan
                    </div>
                    <!-- </v-col> -->
                    <!-- <div class="subtitle body-1 text-center">
                      klik tombol di bawah ini untuk menambahkan program baru
                    </div>
                    <v-col class="text-center">
                      <v-btn color="primary" elevation="0">
                        <v-icon>ri-add-line</v-icon> Program baru
                      </v-btn>
                    </v-col> -->
                  </div>
                </v-col>
              </div>
              <div
                v-else
                v-for="(program, index2) in list"
                :key="'A' + index2"
                class="col-xl-3 col-md-3 col-sm-6 col-6"
              >
                <v-hover v-slot="{ hover }" close-delay="200">
                  <v-card
                    class="mx-auto transparent rounded-lg d-flex flex-column"
                    :elevation="hover ? 16 : 5"
                    :class="{ 'on-hover': hover }"
                    style="height: 380px"
                  >
                    <router-link
                      class=""
                      :to="{ name: 'detail-kelas', params: { id: program.id } }"
                      @click.stop
                    >
                      <v-img
                        :src="program.program_information?.cover?.url"
                        class="img-program rounded-t-lg"
                      />
                      <!-- class="img-program rounded-t-lg" -->
                      <v-card-text
                        class="pa-1 banner-class rounded-b-lg"
                        style="
                          filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03))
                            drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
                        "
                      >
                        <div
                          class="white--text font-weight-bold px-4 subtitle-2 text-no-wrap"
                        >
                          {{
                            program.class_type == 1
                              ? "Webinar"
                              : "Pembelajaran Mandiri"
                          }}
                        </div>
                      </v-card-text>
                      <v-card-subtitle class="px-4 box-content-title">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              class="black--text font-weight-bold text-dots-2 fs-16 fs-xs-14"
                              >{{ program.title }}</span
                            >
                          </template>
                          <span class="text-subtitle-2">{{
                            program.title
                          }}</span>
                        </v-tooltip>
                        <span
                          v-if="
                            program.program_information?.discount_price != 0
                          "
                          class="fs-14 font-weight-bold"
                          ><s
                            >Rp
                            {{
                              toCurrency(
                                program.program_information?.discount_price
                              )
                            }}</s
                          ></span
                        >
                      </v-card-subtitle>

                      <v-card-text class="px-4">
                        <h3 class="font-weight-bold black--text">
                          Rp
                          {{
                            toCurrency(
                              program.program_information?.selling_price
                            )
                          }}
                        </h3>
                      </v-card-text>
                    </router-link>
                    <v-card-actions
                      class="pt-2 pb-3 d-flex justify-center align-center mt-auto"
                    >
                      <v-btn
                        color="#F95712"
                        rounded
                        depressed
                        small
                        class="fs-14 font-weight-medium btn-beli white--text px-8"
                        @click="getDialog(program.id)"
                      >
                        Beli Pelatihan
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </div>
            </div>
            <div v-if="total_program" class="pagination">
              <v-pagination
                v-model="page"
                :length="total_pagination"
                @input="pageChanged"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </section>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title class="font-weight-bold"
            >Pilih Platform Pembelian</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              icon
              @click="dialog = false"
              class="ml-auto"
              color="primary"
            >
              <v-icon>ri-close-fill</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- <v-card-title class="text-h5">
          Pilih Pembelian Program
        </v-card-title> -->

        <v-divider></v-divider>

        <v-card-text class="pb-0 pt-2">
          <div class="col-md-8 text-center mx-auto py-3">
            <div v-for="(item, index) in link" :key="'A' + index">
              <v-btn
                class="rounded-xl mb-2 white--text"
                color="primary"
                :href="item.url"
                target="_blank"
                block
              >
                {{ item.title }}
              </v-btn>
            </div>
            <!-- :href="program.program_information.selling_link" -->
            <!-- <v-btn class="rounded-xl mb-2" color="primary" block>Testing</v-btn> -->
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="filter"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <p class="mb-0">Pencarian Program</p>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="filter = false" color="primary">
            <v-icon>ri-close-fill</v-icon>
          </v-btn>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text class="">
          <v-card class="" elevation="0">
            <div class="box-category mb-2">
              <h3 class="mb-3">Jenis Kelas</h3>
              <v-select
                outlined
                class="rounded-lg"
                placeholder="Pilih jenis kelas"
                v-model="params.class_type"
                item-value="id"
                item-text="name"
                :items="type_class"
              >
              </v-select>
            </div>
            <div class="box-category mb-2">
              <!-- <p class="fs-18 font-weight-medium mb-1">Bidang Studi</p> -->
              <h3 class="mb-3">Bidang Studi</h3>
              <v-autocomplete
                outlined
                class="rounded-lg"
                placeholder="Pilih bidang studi"
                multiple
                chips
                small-chips
                v-model="params.study"
                item-value="id"
                item-text="title"
                :items="list_studi"
              >
              </v-autocomplete>
              <!-- <v-checkbox
                v-for="(item, index) in list_studi" :key="index"
                v-model="studi_id"
                :label="item.title"
                :value="item.id"
                hide-details
                class="mt-1"
                multiple
                ></v-checkbox>
                <v-btn v-if="list_studi.length <= 10" color="primary" class="font-weight-bold mt-2" @click="getStudi(limit='-1')" text block>Tampilkan semua <v-icon>ri-arrow-down-s-line</v-icon></v-btn>
                <v-btn v-else color="primary" class="font-weight-bold mt-2" @click="getStudi(limit='8')" text block>Sembunyikan <v-icon>ri-arrow-up-s-line</v-icon></v-btn> -->
            </div>
            <div class="box-category mb-5">
              <!-- <p class="fs-18 font-weight-medium mb-1">Bidang Studi</p> -->
              <h3 class="mb-3">Harga</h3>
              <v-text-field
                v-model="price_start"
                placeholder="Harga terendah"
                outlined
                prefix="Rp"
                class="rounded-xl mb-3"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-model="price_end"
                placeholder="Harga tertinggi"
                outlined
                prefix="Rp"
                class="rounded-xl"
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="box-button mb-2">
              <div class="row">
                <div class="col-6">
                  <v-btn
                    outlined
                    color="primary"
                    class="rounded-xl font-weight-normal"
                    block
                    small
                  >
                    Reset Filter
                  </v-btn>
                </div>
                <div class="col-6">
                  <v-btn
                    depressed
                    color="primary"
                    class="rounded-xl font-weight-normal"
                    block
                    small
                  >
                    Terapkan
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: "bidang-studi",
  data: () => ({
    params: {},
    list: [],
    ex: [],
    dialog: false,
    filter: false,
    link: [],
    title: "",
    limit: 8,
    page: 1,
    total_pagination: null,
    list_studi: [],
    studi: [],
    studi_id: [],
    price_start: "",
    price_end: "",
    total_program: "",
    search: "",
    numberValue: null,
    numberValue2: null,
    label_program: "",
    class_type: "",

    type_class: [
      { id: "1", name: "Webinar" },
      { id: "2", name: "Pembelajaran Mandiri" },
    ],

    list_progs: [
      { id: "true", name: "Gratis" },
      { id: "false", name: "Berbayar" },
    ],
    // priceRules: [
    //      // (v) => !!v || "Harga terendah Tidak Boleh Kosong",
    //      v => /^[0-9*&./\s/]+$/.test(v) || 'Tidak boleh ada simbol dan huruf',
    //    ],
    //  price2Rules: [
    //      // (v) => !!v || "Harga tertinggi Tidak Boleh Kosong",
    //      v => /^[0-9*&./\s/]+$/.test(v) || 'Tidak boleh ada simbol dan huruf',
    //    ],
  }),
  created() {},
  mounted() {
    this.params = JSON.parse(JSON.stringify(this.$route.query));
    if (this.params.study) this.studi_id = this.params.study.split(",");
    if (this.params.price_start) this.price_start = this.params.price_start;
    if (this.params.price_end) this.price_end = this.params.price_end;
    this.page = this.params.page ? parseInt(this.params.page) : 1;
    // if (!isNaN(this.$route.query.class_type)) {
    //   this.class_type = this.$route.query.class_type
    // }
    // if (this.$route.query.is_free)
    //   this.label_program = this.$route.query.is_free == 'true'
    // if (this.$route.query.class_type) {
    //   this.class_type = this.$route.query.class_type
    // }
    this.getStudi(8);
    this.getList();
  },
  watch: {
    // params: {
    //   handler(val) {
    //     console.log(JSON.stringify(val), JSON.stringify(this.$route.query));
    //     const cek = JSON.stringify(val) == JSON.stringify(this.$route.query);
    //     if (cek) console.log(cek);
    //     if (!cek) this.$router.push({ query: val });
    //     // console.log(this.$route, val);
    //     // .catch(() => {});
    //   },
    //   deep: true,
    // },
    studi_id: function (val) {
      console.log("p");
      this.$set(this.params, "study", val.join(","));
    },
    price_start: function (val) {
      this.price_start = val
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.params.price_start = val
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, "");
    },
    // numberValue: function(newval){
    //   const result = newval.replace(/\D/g, "")
    //     .replace(/\B(?=(\d{3})+(?!\d))/g, "");
    //   this.numberValue = result;
    // },
    price_end: function (val) {
      this.price_end = val
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.params.price_end = val
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, "");
    },
    // numberValue2: function(newval){
    //   const result = newval.replace(/\D/g, "")
    //     .replace(/\B(?=(\d{3})+(?!\d))/g, "");
    //   this.numberValue2 = result;
    // }
  },
  methods: {
    filterList() {
      const val = this.params;
      const cek = JSON.stringify(val) == JSON.stringify(this.$route.query);
      if (!cek) this.$router.push({ query: val });
    },
    pageChanged(page) {
      this.params.page = page;
      this.filterList();
    },
    resetList() {
      this.$delete(this.params, "page");
      this.$delete(this.params, "study");
      this.$delete(this.params, "price_start");
      this.$delete(this.params, "price_end");
      this.filterList();
    },
    getStudi(limit) {
      // console.log(limit)
      this.limit = limit;
      this.axios
        .get(
          `study/v1/public/list/${process.env.VUE_APP_ACCESS_KEY}?q=&page=1&limit=-1&sort=created_at&sort_type=asc`
        )
        .then((response) => {
          let res = response.data.data;
          this.list_studi = res.list;
        });
    },
    getList() {
      this.axios
        // .get(`users/v1/public/program/list?status=publish&page=1&limit=10&sort=created_at&dir=desc&study=${this.$route.params.id}`,{
        //     headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }
        // .get(`users/v1/public/program/list?&page=1&limit=10&sort=_id&dir=desc&study=${this.studi_id}&price_start=${this.price_start}&price_end=${this.price_end}`,{
        //   })
        .get(`users/v1/public/program/list`, {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
          params: {
            ...this.params,
            status: "publish",
            sort: "created_at",
            dir: "desc",
            limit: 12,
            // page: this.page,
            // study: this.studi_id,
            // price_start: this.numberValue,
            // price_end: this.numberValue2,
            // q: this.search,
            // is_free: this.$route.query.is_free ? this.$route.query.is_free : '',
            // class_type: this.class_type
          },
        })

        .then((response) => {
          let res = response.data.data;
          this.list = res.list;
          this.total_program = res.total;
          this.total_pagination = res.total_page;
          this.filter = false;

          // this.title = res.list[0].learning_targets.studies.title;
        });
    },
    getDialog(id) {
      this.axios
        .get(`/users/v1/public/program/detail/${id}`)
        .then((response) => {
          // let res = response.data.data;
          this.link = response.data.data.program_information.selling_link;
          this.dialog = true;
        });
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      // var formatter = new Intl.NumberFormat('en-IN', {
      var formatter = new Intl.NumberFormat("de-DE", {
        // style: 'currency',
        // currency: 'IDR',
        // minimumFractionDigits: 0
      });
      return formatter.format(value);
    },
  },
};
</script>

<style>
#section-trans {
  background: linear-gradient(#f05326, #ff9a3f);
  padding: 80px 0;
  /*min-height: 100vh;*/
}
#section-trans-2 {
  padding: 100px 0;
  /*background: #fff;*/
}
.after-line::after {
  content: "";
  margin: 10px auto;
  border-bottom: 4px solid #3e7fb5;
  width: 15%;
  display: block;
}
.filter-search {
  right: 40%;
  left: 35%;
  bottom: 2%;
  position: fixed;
  line-height: inherit;
  z-index: 99;
}
.box-search {
  max-width: 400px;
  margin-left: auto !important;
}
.img-studi {
  width: 100%;
  height: 150px;
  object-fit: fill;
}
/*@media (max-width: 768px) {
  #banner .v-image{
    height: auto!important;
  }
}*/
@media (max-width: 425px) {
  .box-search {
    max-width: 100%;
  }
}
@media (max-width: 375px) {
}
</style>
