import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '../router'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.headers.common['Content-Type'] = 'application/json' 

// create interceptor for configuration request 
// axios.interceptors.request.use(config => {
//   config.timeout = 50; // Wait for 5 seconds before timing out
//   return config;
// });

// create interceptor for checking token is expired or not
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      return Promise.reject({
        message: 'Request Timeout',
        response: error
      });
    }

    if (error.response.status === 401) {
      localStorage.removeItem("ytc_lms_auth");
      window.location.reload();
      setTimeout(() => {
        router.push("/login");
      } , 1000)
    }
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios)