var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-divider'),_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"mb-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9","md":"9","lg":"9"}},[_c('div',{ref:"stage",staticClass:"mr-4",staticStyle:{"position":"relative","user-select":"none"},style:({ cursor: _vm.selecting ? '' : '' }),on:{"mouseup":_vm.handleStageMouseUp,"mousemove":_vm.handleStageMouseMove}},[(_vm.selecting)?_c('div',{staticClass:"rounded-pill",class:{ primary: !_vm.review, success: _vm.review },staticStyle:{"position":"absolute","height":"32px","transform-origin":"16px","opacity":"0.2"},style:(_vm.selectingStyle)}):_vm._e(),_vm._l((_vm.highlighter),function(style,index){return _c('div',{key:index,staticClass:"rounded-pill primary",staticStyle:{"position":"absolute","height":"32px","transform-origin":"16px","opacity":"0.2"},style:(style)})}),_vm._l((_vm.randomQuestion),function(hor,i1){return _c('div',{key:`hor-${i1}`,staticClass:"d-flex flex",staticStyle:{"padding-bottom":"10px"}},_vm._l((hor),function(q,i2){return _c('div',{key:`char-${i1}${i2}`,staticClass:"text-uppercase d-flex flex align-center justify-center",style:({
                  width: `${_vm.blockSize}px`,
                  height: `${_vm.blockSize}px`,
                  position: 'relative',
                })},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('span',{staticClass:"d-flex rounded-circle d-flex align-center justify-center",class:{ primary: hover && !_vm.review },staticStyle:{"cursor":"pointer","width":"32px","height":"32px"},attrs:{"id":`char-${i1}${i2}`},on:{"mousedown":function($event){return _vm.handleQuestionMouseDown($event, [i1, i2])},"mouseup":function($event){$event.stopPropagation();return _vm.handleQuestionMouseUp($event, [i1, i2])}}},[_vm._v(" "+_vm._s(q.char)+" ")])]}}],null,true)})],1)}),0)})],2)]),_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"primary white--text justify-center"},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("ri-file-list-line")]),_c('span',[_vm._v("KATA")])],1),_c('div',{staticClass:"pa-4 grey overflow-auto",style:({ height: `450px` })},[_c('v-row',_vm._l((_vm.questions),function(item){return _c('v-col',{key:item,attrs:{"cols":"12"}},[_c('div',{staticClass:"text-uppercase font-weight-bold text-center",class:{
                      'text-decoration-line-through': _vm.isAnswered(item) && !_vm.review,
                    }},[_vm._v(" "+_vm._s(item)+" ")])])}),1)],1)],1)],1)],1)],1),[(_vm.quizData.setting.time.enable)?_c('div',{staticClass:"d-flex align-center mb-4"},[_c('v-icon',{staticClass:"ritimer",attrs:{"left":""}},[_vm._v("ri-time-line")]),_c('div',{staticClass:"mr-2"},[_vm._v("Sisa Waktu Berlangsung:")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.timer))])],1):_vm._e(),(!_vm.review)?_c('div',[_c('v-btn',{staticClass:"rounded-pill",attrs:{"loading":_vm.isLoading,"color":"primary","elevation":"0"},on:{"click":_vm.onSaveAnswerData}},[_vm._v(" Submit ")])],1):_vm._e()]],2),_c('v-snackbar',{attrs:{"top":"","timeout":5000,"color":"primary","outlined":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"small":"","icon":"","color":"error"},on:{"click":function($event){_vm.snackbar.state = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("$close")])],1)]}}]),model:{value:(_vm.snackbar.state),callback:function ($$v) {_vm.$set(_vm.snackbar, "state", $$v)},expression:"snackbar.state"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.snackbar.text)}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }