<template>
  <div>
    <hr style="border-color: transparent" />
    <section class="mt-5 mb-5 white px-10 py-7 text-justify">
      <!-- <h1 class="text-h4 ">{{ title }}</h1>
      <v-divider class="my-4"></v-divider> -->
      <div v-html="content"></div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      content: "",
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const response = await this.axios.get(`/company/v1/public/company-page/${process.env.VUE_APP_ACCESS_KEY}/privacy-policy`)
        this.title = response.data.data.title
        this.content = response.data.data.content
      } catch (error) {
        console.log(error)
      }
    }
  },
}

</script>