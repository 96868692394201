<template>
  <div>
    <!-- <div class="px-4 py-2">Mini Quiz. Jawab Pertanyaan Dibawah Ini!</div> -->
    <v-divider></v-divider>
    <v-skeleton-loader v-if="question_loading" class="mx-auto" type="image">
    </v-skeleton-loader>
    <div v-else class="pa-4">
      <!-- navigation -->
      <div class="d-flex justify-space-between mb-4">
        <div class="d-flex align-center">
          <v-btn
            width="48"
            min-width="48"
            outlined
            small
            color="primary"
            :disabled="gameplay.current <= 1"
            @click="beforeQuestion()"
          >
            <v-icon>$prev</v-icon>
          </v-btn>
          <div class="mx-4">
            Soal no
            <span class="primary--text">{{ this.gameplay.current }}</span>
            dari
            {{ questions.length }}
          </div>
          <v-btn
            width="48"
            min-width="48"
            small
            outlined
            color="primary"
            :disabled="gameplay.current == questions.length"
            @click="nextQuestion()"
          >
            <v-icon>$next</v-icon>
          </v-btn>
        </div>
        <div v-if="!review">
          <v-btn
            v-if="questions.length == gameplay.current"
            :loading="isLoading"
            color="primary"
            elevation="0"
            class="rounded-pill"
            @click="onUpdateAnswer('submit')"
          >
            Submit
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            v-if="questions.length == gameplay.current"
            :loading="isLoading"
            color="primary"
            elevation="0"
            class="rounded-pill"
            @click="$emit('click:back-to-complete')"
          >
            Kembali ke Nilai
          </v-btn>
        </div>
      </div>

      <div>
        <!-- content -->
        <div v-if="currentQuestion" class="mb-4">
          <div class="mb-4">
            <div v-if="currentQuestion.question.audio">
              <audio controls>
                <source :src="currentQuestion.question.audio" />
              </audio>
            </div>
            <div v-if="currentQuestion.question.image">
              <img
                style="height: 200px"
                :src="currentQuestion.question.image"
                :title="currentQuestion.question.text"
              />
            </div>
          </div>
          <div class="mb-4 mx-4" v-html="currentQuestion.question.text"></div>
          <div class="d-flex justify-center" v-if="!review">
            <v-btn
              class="flex-grow-1 mx-4"
              v-for="option in currentQuestion.options"
              :key="option.text"
              :value="option.text"
              rounded
              :outlined="answer == option.text ? false : true"
              color="primary"
              style="text-transform: none"
              @click="answer = option.text"
            >
              {{ option.text }}
            </v-btn>
          </div>

          <!-- OPTION REVIEW -->
          <div class="d-flex justify-center" v-else>
            <v-btn
              class="flex-grow-1 mx-4"
              v-for="option in currentQuestion.options"
              :key="option.text"
              :value="option.text"
              rounded
              :outlined="
                currentQuestion?.correct_answers[0] ==
                option.text
                  ? false
                  : true
              "
              color="primary"
              style="text-transform: none"
            >
              {{ option.text }}
            </v-btn>
          </div>
        </div>
      </div>

      <!-- timer -->
      <div v-if="!review">
        <div v-if="quizData.setting.time.enable" class="d-flex align-center">
          <v-icon left class="ritimer">ri-time-line</v-icon>
          <div class="mr-2">Sisa Waktu Berlangsung:</div>
          <div class="font-weight-bold">{{ timer }}</div>
        </div>
      </div>

      <!-- review -->
      <div v-else>
        <div class="font-weight-bold mb-2">Pembahasan Soal</div>
        <v-alert outlined>
          <span>Jawaban: &nbsp;</span>
          <span
            class="font-weight-bold"
            v-if="quizData.setting.show_answer_explanation"
            >{{ currentQuestion?.correct_answers[0] }}</span
          >
          <p
            style="font-size: 13px"
            v-if="quizData.setting.show_explanation"
            v-html="currentQuestion?.explanation.text"
          ></p>
          <v-img
            style="cursor: pointer"
            v-if="currentQuestion?.explanation.image"
            :src="currentQuestion?.explanation.image"
            width="100"
            @click="dialogPreview = true"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-alert>
      </div>
    </div>

    <v-dialog v-model="dialogPreview" width="200">
      <v-img
        class="mx-auto"
        :src="currentQuestion?.explanation.image"
        width="200"
      >
      </v-img>
    </v-dialog>

    <v-snackbar
      top
      v-model="snackbar.state"
      :timeout="5000"
      color="primary"
      outlined
    >
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    review: Boolean,
    quizData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    question_loading: false,
    isLoading: false,
    dialogPreview: false,
    seq: 1,
    img: "https://i.imgur.com/Eb4HIqp.png",
    answer: undefined,
    questions: [],
    saveAnswer: [],
    gameplay: {},
    //   text: "Benarkah Gilang ganteng?",
    //   corect_feedback:
    //     "karena norma kesusilaan merupakan aturan dasar hidup tentang perilaku yang dinilai baik maupun buruk.",
    // },
    snackbar: {
      state: false,
      text: "",
    },
    intervalTimer: null,
    timer: 0,
  }),
  computed: {
    currentQuestion() {
      try {
        const index = this.gameplay.current - 1;
        return this.questions[index];
      } catch (error) {
        return {};
      }
    },
  },
  mounted() {
    this.setQuestions();
  },
  unmounted() {
    clearInterval(this.intervalTimer);
  },
  methods: {
    handleCountdownTimer() {
      let setting = this.quizData.setting;
      var timer = setting.time.duration * 60;
      let minutes;
      let seconds;

      this.intervalTimer = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.timer = minutes + ":" + seconds;

        // console.log(this.timer);

        if (--timer <= 0) {
          // timer = 60 * 10;
          clearInterval(this.intervalTimer);
          this.saveAnswerQuiz();
        }
      }, 1000);
    },
    beforeQuestion() {
      // current answer
      this.currentQuestion.answer = this.answer;

      this.gameplay.current--;

      // before answer question
      this.answer = this.currentQuestion.answer;
    },

    nextQuestion() {
      // current answer
      this.currentQuestion.answer = this.answer;

      this.gameplay.current++;

      // next answer question
      this.answer = this.currentQuestion.answer;
    },
    onUpdateAnswer(type) {
      // this.$set(this.currentQuestion, 'answer', this.answer)

      if (type == "next") {
        this.gameplay.current++;
      } else {
        // Last Question set answer
        this.currentQuestion.answer = this.answer;

        this.submitAnswer();
      }
    },
    async setQuestions() {
      this.question_loading = true
      await this.axios
        .get(`/gameplay/v1/public/detail-multiple`, {
          params: {
            ids: this.quizData.gameplays.toString(),
          },
        })
        .then((res) => res.data)
        .then(async (res) => {
          // console.log(res.data);

          this.$set(this.gameplay, "current", 1);
          this.$set(this.gameplay, "list_question", res.data);

          res.data.forEach((item) => {
            item.answer = null;
          });

          this.questions = res.data;
        })
        .finally(() => {
          this.question_loading = false
        });

      if (this.quizData.setting.time.enable) {
        this.handleCountdownTimer();
      }
    },
    submitAnswer() {
      this.questions.forEach((item) => {
        let findindex = this.saveAnswer.findIndex(
          (v) => v.gameplay_id == item.id
        );
        let find = this.saveAnswer.find((v) => v.gameplay_id == item.id);

        if (item.answer) {
          if (!find) {
            this.saveAnswer.push({
              gameplay_id: item.id,
              answer: [item.answer],
            });
          } else {
            this.saveAnswer[findindex].answer = [item.answer];
          }
        }
      });

      if (this.saveAnswer.length != this.questions.length) {
        return (this.snackbar = {
          state: true,
          text: "Soal belum terjawab keseluruhan",
        });
      }

      if (this.quizData.setting.retake == -1) {
        return this.saveAnswerQuiz();
      }

      if (
        this.quizData.retake != null &&
        this.quizData.retake >= this.quizData.setting.retake
      ) {
        this.$emit("click:close-modal");
        clearInterval(this.intervalTimer);
        return (this.snackbar = {
          state: true,
          text: `Kesempatan mengulang quiz sudah mencapai batas maksimal pada aktifitas ini sebanyak ${this.quizData.setting.retake} kali.`,
        });
      }
      this.saveAnswerQuiz();
    },
    async saveAnswerQuiz() {
      this.isLoading = true;

      let setting_timer = this.quizData.setting.time.duration * 60;
      let last_duration_minutes = 0;
      let minutes = 0;
      let seconds = 0;

      if (this.quizData.setting.time.enable) {
        last_duration_minutes =
          Number(this.timer.split(":")[0]) +
          Number(this.timer.split(":")[1] / 60);

        minutes = parseInt(setting_timer / 60, 10) - last_duration_minutes;

        seconds = parseInt(minutes * 60, 10);
      }

      let data = {
        member_redeem_id: this.$route.params.id,
        topic_id: this.quizData.topic_id,
        activity_id: this.quizData.activity_id,
        answer_duration: seconds,
        answer: this.saveAnswer,
      };

      await this.axios
        .post(`/gameplay/v1/answer`, data)
        .then((response) => {
          let res = response.data;
          this.snackbar = {
            state: true,
            text: "Jawaban berhasil dikirimkan",
          };
          clearInterval(this.intervalTimer);

          this.$emit("click:submit", res.data);

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          clearInterval(this.intervalTimer);

          let message = "";
          if (error instanceof Error) {
            message = error.message;
            if (error.response?.data?.message) {
              message = error.response.data.message;
            }
          }

          this.snackbar = {
            state: true,
            text: message,
          };

          if (
            message ==
            "kesempatan mengulang quiz sudah mencapai batas maksimal pada aktifitas ini"
          ) {
            setTimeout(() => {
              this.$emit("click:close-modal");
            }, 2000);
          }
        });
    },
  },
};
</script>
<style>
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
</style>
