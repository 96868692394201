<template>
  <div v-if="isLoading && title && description">
    <v-row style="height: 100vh;" class="pa-5" justify="center" align="center">
      <v-card class="pa-3 text-center" height="70vh" width="80vw" flat>
        <v-row justify="center" align="center" style="height: 100%;">
          <v-col>
            <img v-if="title == 'Loading ....'" :src="require('@/assets/images/icon/loading.gif')" alt="Icon Loading" class="mx-auto" width="150">
            <div v-else>
              <img v-if="success" :src="require('@/assets/images/icon/check-circle.png')" alt="Icon Success" class="mx-auto" width="150">
              <img v-else :src="require('@/assets/images/icon/circle-x.png')" alt="Icon Failed" class="mx-auto" width="150">
            </div>
    
            <div class="my-8 text-h5 text-uppercase font-weight-black">{{ title }}</div>
            <div class="my-8 text-capitalize">{{ description }}</div>
            <div v-if="title == 'Loading ....'" class="d-flex justify-center align-center">
              <v-alert 
                style="width: 50%;"
                type="error" outlined>
                PENTING! Jangan tinggalkan halaman ini sebelum mendapatkan notifikasi berhasil atau gagal.
              </v-alert>
            </div>
            <v-btn 
              v-if="title != 'Loading ....'"
              class="primary"
              @click="$router.push('/kelas')">
              Kembali ke Pelatihan
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
  <NotFound v-else/>
</template>

<script>
import NotFound from '../errors/NotFound.vue'
export default {
  components: {
    NotFound
  },
  data() {
    return {
      isLoading: true,
      success: false,
      state: "",
      title: "Loading ....",
      error_code: "",
      description: "Silahkan tunggu sebentar",
    }
  },
  created() {
    if(Object.keys(this.$route.query).length > 0) {
      this.isLoading = true
      if(this.$route.query.error_code) {
        this.success = false
        this.title = this.$route.query.error
        this.description = this.$route.query.error_description
        this.state = this.$route.query.state
        setTimeout(() => {
          this.onRedeemCallback()
        }, 1500)
      }else {
        this.success = true
        this.state = this.$route.query.state
        setTimeout(() => {
          this.onRedeemCallback()
        }, 1500)
      }
    }else {
      this.isLoading = false
    }
  },
  methods: {
    encodingUrl(params) {
      let query = '?' +
      Object.keys(params)
        .map(key => {
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          )
        })
        .join('&')
      return query
    },
    async onRedeemCallback() {
      this.isLoading = true
      await this.axios.get(`/users/v1/myprogram/check-status-prakerja/${this.state}${this.encodingUrl(this.$route.query)}`).then((response) => {
        let res = response.data
        if(res.data.status) {
          this.success = true
          this.title = "Redeem Kode Berhasil"
          this.description = "Selamat Anda Telah Berhasil Melakukan Redeem Kode Prakerja"
          this.isLoading = true
        }else {
          // KETIKA GAGAL TAMPILKAN PESAN KESALAHAN
          this.success = false
          this.title = "Redeem Kode Gagal"
          this.description = "Terjadi Kesalahan pada sistem"
          this.isLoading = true
        }
      }).catch((error) => {
        this.success = false
        this.title = "Redeem Kode Gagal"
        this.description = error.response.data.message ? error.response.data.message : "Data Redeem tidak ditemukan"
        this.isLoading = true
      })
    }
  }
}
</script>