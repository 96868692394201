import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/firebase-messaging'

	const firebaseConfig = {
		apiKey: "AIzaSyCPMce5_ZV5IG0Kiuok_Lf8LnWiX1C0Ffc",
		authDomain: "bakat-dev.firebaseapp.com",
		projectId: "bakat-dev",
		storageBucket: "bakat-dev.appspot.com",
		messagingSenderId: "87381884606",
		appId: "1:87381884606:web:d0a56892b18668743c68ef"
	};
firebase.initializeApp(firebaseConfig)

export default firebase