<template>
  <div>
    <div v-if="isLoading" class="d-flex flex-column justify-center align-center mx-auto" style="height: 100vh; width: 100vw;">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate>
      </v-progress-circular>
      <div class="mt-10">Menyiapkan aplikasi untuk Anda, silahkan perbolehkan notifikasi ....</div>
    </div>
    <v-row v-else align-content="center">
      <v-col class="pa-0" md="12" style="height: 100vh">
        <v-row no-gutters>
          <v-col class="white mx-auto pa-0" md="6" style="height: 100vh">
            <v-spacer class="py-3"></v-spacer>
            <router-link
              class="font-weight-medium text-decoration-none px-8"
              tabindex="-1"
              color="primary"
              :to="{ name: 'home' }"
            >
              <span><v-icon color="primary"> mdi-arrow-left</v-icon> Kembali</span>
            </router-link>
            <v-spacer class="py-5"></v-spacer>
            <v-form
              class="px-8"
              @submit.prevent="submit"
              lazy-validation
              ref="form"
              v-model="valid"
            >
              <div class="fs-24 font-weight-bold mb-4">Login dulu yuk</div>
              <div class="color-disable mb-4">
                Belum mempunyai akun ?
                <router-link
                  class="font-weight-medium text-decoration-none"
                  tabindex="-1"
                  color="primary"
                  :to="{ name: 'registration' }"
                >
                  <span>Daftar sekarang</span>
                </router-link>
              </div>
  
              <v-alert
                type="error"
                text
                dense
                prominent
                v-show="response !== null"
                >{{ response }}</v-alert
              >
              <v-alert
                type="success"
                text
                dense
                prominent
                v-show="responsesukses !== null"
                >{{ responsesukses }}</v-alert
              >
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">Nomor Whatsapp</span>
                  </div>
                  <!-- <v-text-field
                    class="rounded-xl"
                    v-model="email"
                    placeholder="Masukkan email Anda"
                    :rules="emailRules"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field> -->
                  <v-text-field
                    class="rounded-xl"
                    v-model="nomer"
                    type="number"
                    placeholder="081234xxx"
                    :rules="nomerRules"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-2">
                    <span class="font-weight-medium">Password</span>
                    <router-link
                      :to="{ name: 'password-send-phone' }"
                      class="float-right text-decoration-none"
                      tabindex="-1"
                    >
                      Lupa Password?
                    </router-link>
                  </div>
                  <v-text-field
                    class="rounded-xl"
                    v-model="password"
                    placeholder="Min. 8 karakter"
                    :rules="pswRules"
                    outlined
                    hide-details="auto"
                    required
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="">
                  <v-btn
                    large
                    elevation="0"
                    type="submit"
                    color="primary"
                    :loading="submiting"
                    class="rounded-xl"
                    block
                  >
                    Masuk
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "@/firebase";

export default {
  name: "login",
  data() {
    return {
      isLoading: false,
      show: false,
      errors: [],
      valid: true,
      nomer: "",
      password: "",
      token: "",
      nomerRules: [
        (v) => !!v || "Nomor Whatsapp tidak boleh kosong"
      ],
      pswRules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password harus minimal 8 karakter",
      ],
      submiting: false,
      response: null,
      responsesukses: null,
    };
  },
  created() {
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.getToken();
  },
  methods: {
    OnGoogleAuthFail() {
      // console.log(error);
    },
    async loginWithGoogle() {
      const googleUser = await this.$gAuth.signIn();
      // const authCode = await this.$gAuth.getAuthCode();
      // console.log(googleUser);
      this.$store
        .dispatch("login_google", googleUser.uc.id_token)
        .then(() => {
          this.submiting = false;
          this.redirectAfterLogin();
        })
        .catch((error) => {
          this.submiting = false;
          console.error(error);
          this.response = error;
        });
    },
    async getToken() {
      this.isLoading = true;
      if (firebase.messaging.isSupported()) {
        if (!this.token) {
          // firebase messaging request permission
          firebase.messaging().requestPermission()
          .then(async () => {
            console.log('Notification permission granted.');

            // get user agent for detect browser if safari then set isSafari to true
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/iPad|iPhone|iPod/.test(userAgent) || !window.MSStream || navigator.userAgent.indexOf("Safari") > -1) {
              this.isLoading = false;
            }
            
            try {
              const token = await firebase.messaging().getToken();
              // console.log("🚀 ~ file: login.vue:203 ~ .then ~ token:", token)
              if (token) {
                this.token = token;
                localStorage.setItem('fcm_token', token);
                setTimeout(() => {
                  this.isLoading = false;
                }, 1000);
              } else {
                this.isLoading = false;
                console.log('No registration token available. Request permission to generate one.');
              }
            } catch (error) {
              this.isLoading = false;
              console.log('An error occurred while retrieving token. ', error);
            }
          })
          .catch((err) => {
            this.isLoading = false;
            console.log('Unable to get permission to notify.', err);
          });
        }
      }else {
        if (!this.token) {
          console.log('Firebase messaging is not supported.');

          if(navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'notifications' })
            .then((permission) => {
              if (permission.state === 'granted') {
                this.isLoading = true;
                // If push notification is allowed then get token from firebase messaging
                try {
                  firebase.messaging().getToken()
                  .then((token) => {
                    if (token) {
                      this.token = token;
                      localStorage.setItem('fcm_token', token);
                      this.isLoading = false;
                    } else {
                      this.isLoading = false;
                      console.log('No registration token available. Request permission to generate one.');
                    }
                  })
                } catch (error) {
                  this.isLoading = false;
                  console.log('An error occurred while retrieving token. ', error);
                }


              } else if (permission.state === 'denied') {
                this.isLoading = false;
                console.log('User has blocked notifications.');
              } else if (permission.state === 'prompt') {
                this.isLoading = false;
                console.log('User has not yet been asked for notification permission');
              }
            }).catch((err) => {
              this.isLoading = false;
              console.log('An error occurred while retrieving token. ', err);
            })
          }else if(Notification.requestPermission) {

            try {
              Promise.resolve(Notification.requestPermission().then(async (permission) => {
  
                if(permission === 'granted') {
                  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
                  if (/iPad|iPhone|iPod/.test(userAgent) || !window.MSStream || navigator.userAgent.indexOf("Safari") > -1) {
                    this.isLoading = false;
                  }
                  
                  try {
                    const token = await firebase.messaging().getToken();
                    // console.log("🚀 ~ file: login.vue:203 ~ .then ~ token:", token)
                    if (token) {
                      this.token = token;
                      localStorage.setItem('fcm_token', token);
                      setTimeout(() => {
                        this.isLoading = false;
                      }, 1000);
                    } else {
                      this.isLoading = false;
                      console.log('No registration token available. Request permission to generate one.');
                    }
                  } catch (error) {
                    this.isLoading = false;
                    console.log('An error occurred while retrieving token. ', error);
                  }
                  
                } else {
                  this.isLoading = false;
                  console.log('User has blocked notifications.');
                }
              }));
              
            } catch (error) {
              this.isLoading = false;
              console.log('An error occurred while retrieving token. ', error);
            }
          }
          
        }

      }

    },
    async submit() {
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let phone = this.nomer;
        let password = this.password;
        let token = this.token;
        
        this.$store
          .dispatch("login", { phone, password, token })
          .then(async (res) => {
            this.submiting = false;
            await this.$store.dispatch('get_user')
            if (res.is_verified === true) {
              this.$root.$emit("setDialog",true);
              this.redirectAfterLogin();
            }else{
              this.$store.dispatch("logout").then(() => {
                this.$router.push("/auth/kirim-otp");
              });
            }
          })
          .catch((error) => {
            this.submiting = false;
            this.$refs.form.reset();
            // console.log(error.response.data.data.message)            
            this.response = error.response.data.message;
            // this.response = "Nomor Telepon dan Password tidak cocok";
            // console.error(error);
          });
      }else {
        this.submiting = false;
        this.response = "Nomor Whatsapp dan password tidak boleh kosong";
      }
    },
    redirectAfterLogin() {
      if (localStorage.getItem("ytc_prakerja_redirect") != null) {
        this.$router.push(localStorage.getItem("ytc_prakerja_redirect"));
        localStorage.removeItem("ytc_prakerja_redirect");
        return;
      }
      if (this.user.is_updated_profile) {
        setTimeout(() => {
          this.$router.push("/beranda");
        }, 20);
      }else {
        this.$router.push("/account");     
      }
    },
  },
};
</script>

<style>
.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
</style>
