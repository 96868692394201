<template>
  <div>
    <v-card> 
      <!-- v-if="$vuetify.breakpoint.name != 'xs'" -->

      <v-app-bar clipped-left clipped-right color="primary" class="ma-0" dark>
        <div class="d-flex justify-space-between align-center">
          <v-app-bar-nav-icon v-if="leftdrawer" class="mr-2" @click.stop="leftdrawer = !leftdrawer">
            <v-icon>ri-arrow-left-line</v-icon>
          </v-app-bar-nav-icon>
          <div v-if="leftdrawer">
            <div class="font-weight-bold text-capitalize body-1">{{ detailTrainer.name }}</div>
            <div class="caption font-weight-medium">{{ detailTrainer.online ? 'Online' : 'Offline' }}</div>
          </div>
          <h4 v-else>Daftar Trainer</h4>
        </div>
        <v-spacer></v-spacer>
        <v-btn @click="modalTrainers = true" v-if="!leftdrawer && rooms.length > 0" class="white--text" icon>
          <v-icon>ri-add-line</v-icon>
        </v-btn>
      </v-app-bar>


      <div>
        <v-card v-if="rooms.length > 0 && !leftdrawer">
          <RoomList :id_program="id_program" @click:selectedRoom="onClickShow"/>
        </v-card>

        <!-- First Time must selected trainer who want to chatting -->
        <v-card v-if="rooms.length <= 0">
          <v-card-text class="pa-0 ma-0">
            <v-card v-for="trainer in trainers" :key="trainer.id" flat>
              <v-list class="pa-0 ma-0">
                <v-list-item one-line @click="createChatRoom(trainer)">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ trainer.fullname }}</v-list-item-title>
                    <v-list-item-subtitle>{{ trainer.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn 
                      color="primary" 
                      class="font-weight-bold"
                      depressed
                      x-small>
                      <v-icon left>ri-chat-3-line</v-icon>
                      Mulai
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
            </v-card>
          </v-card-text>
        </v-card>
      </div>


      <!-- CONTENT MESSAGE -->
      <v-card v-show="leftdrawer">
        <MessageContainer :act="act" :topic="topic" :detailClass="detailClass"/>
      </v-card>


    </v-card>
    <!-- <v-card v-else>
      <v-app-bar clipped-left clipped-right color="primary" class="ma-0" dark>
        <div class="d-flex align-center">
          <v-app-bar-nav-icon class="mr-2" @click.stop="leftdrawer = !leftdrawer"></v-app-bar-nav-icon>
          <h4>Diskusi Private</h4>
        </div>
      </v-app-bar>

      <div>
        <v-list-item two-line class="px-0">
          <v-avatar size="38" class="mr-2" color="indigo">
            <v-icon v-if="!rooms.isPrivate" dark>mdi-account-circle</v-icon>
            <img v-if="rooms.isPrivate" :src="`https://placehold.co/600x400?text=A`" :alt="`${rooms.name}`" />
          </v-avatar>

          <v-list-item-content>
            <v-list-item-title class="body-2">{{ rooms.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card> -->


    <v-dialog v-model="modalTrainers" persistent scrollable :width="$vuetify.breakpoint.name == 'xs' ? '' : '500'" :fullscreen="$vuetify.breakpoint.name == 'xs' ? true : false">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text">
            Data Trainer
          </div>
          <!-- <v-spacer></v-spacer>
          <v-icon @click="modalTrainers = false" color="white">mdi-close-circle</v-icon> -->
        </v-card-title>
        <v-card-text style="max-height: 600px;" class="pa-0">
          <v-card v-for="trainer in trainers" :key="trainer.id" flat>
            <v-list class="py-0">
              <v-list-item one-line @click="createChatRoom(trainer)">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{ trainer.fullname }}</v-list-item-title>
                  <v-list-item-subtitle>{{ trainer.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn 
                    color="primary" 
                    class="font-weight-bold"
                    depressed
                    x-small>
                    <v-icon left>ri-chat-3-line</v-icon>
                    Mulai
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-card>
        </v-card-text>
        <v-card-actions class="mb-5">
          <v-btn block color="primary" @click="modalTrainers = false">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import RoomList from './rooms/RoomLists.vue'
import MessageContainer from './messages/MessageContainer'
import firebase from '@/firebase';
export default {
  name: 'discussion-private',
  props: {
    title: String,
    act: Object,
    topic: Object,
    detailClass: Object,
    id_topic: String,
    id_act: String,
    id_program: String,
    trainers: Array,
  },
  components: {
    RoomList,
    MessageContainer
  },
  data() {
    return {
      leftdrawer: null,
      modalTrainers: null,
      detailTrainer: {
        name: "",
        email: "",
        online: false
      },
      rooms: [],
      onlineUsers: [],
      onlineUserIDs: [],
    }
  },
  computed: {
    ...mapState('trainerModule', {
      trainerState: state => state.trainers
    }),
    ...mapState('roomModule', {
      userState: state => state.users
    }),
    ...mapState('roomModule', {
      roomID: state => state.activeRoom
    }),
  },
  created() {
    this.getTrainer(this.$route.params.id)

    firebase.firestore().collection(process.env.VUE_APP_FIREBASE_COLLECTION_ROOM)
      .where("program_id", "==", this.id_program)
      // .where("topic_id", "==", this.id_topic)
      // .where("activity_id", "==", this.id_act)
      .where("member.id", "==", this.$store.state.auth.user.id)
      .onSnapshot((snapshot) => {
        const rooms = [];
        snapshot.forEach((doc) => {
          const room = doc.data();
          room.id = doc.id
          rooms.push(room);
        });

        this.rooms = rooms;
        this.isLoaded = true;
      });

    // GET ONLINE USER
    let datetime = new Date();
    datetime.setHours(datetime.getHours() - 1);
    firebase.firestore().collection(process.env.VUE_APP_FIREBASE_COLLECTION_ONLINE)
    .where('login_at', '>=', datetime)
    .onSnapshot((snapshot) => {
      const users = []
      const userIDs = []
      snapshot.forEach((doc) => {
        const onlineTrainer = doc.data();
          users.push(onlineTrainer)
          userIDs.push(onlineTrainer.id)
      });

      this.onlineUsers = users
      this.onlineUserIDs = userIDs
    });
  },
  methods: {
    ...mapActions('roomModule', ['selectRoom', 'clearRoom', 'createChatRoom']),
    ...mapActions('trainerModule', ['getTrainer']),

    onClickShow(room) {
      let onlineTrainer = false
      if(this.onlineUserIDs.indexOf(room.trainer.id) >= 0) {
        onlineTrainer = true
      }
      else {
        onlineTrainer = false
      }
      this.detailTrainer = {
        name: room.trainer.name,
        email: room.trainer.email,
        online: onlineTrainer
      }

      this.leftdrawer = true
    },

    createChatRoom(trainer) {

      this.clearRoom()
      let params = {
        room: null,
        program_id: this.id_program,
        trainer_id: trainer.id
      }

      let findRoom = this.rooms.find(item => item.trainer_id == trainer.id)  

      // console.log(findRoom);

      if(findRoom) {
        params = {
          room: findRoom.id,
          program_id: this.id_program,
          trainer_id: trainer.id
        }
      }

      // console.log(params);
      
      this.selectRoom(params)
      
      let onlineTrainer = false
      if(this.onlineUserIDs.indexOf(trainer.id) >= 0) {
        onlineTrainer = true
      }
      else {
        onlineTrainer = false
      }
      this.detailTrainer = {
        name: trainer.fullname,
        email: trainer.email,
        online: onlineTrainer
      }

      this.modalTrainers = false
      this.leftdrawer = true
      
    },
  }
}
</script>