<template>
  <div>
    <v-card-title class="d-flex justify-space-between pa-0" style="font-size: 15px;">
      <!-- <div class="px-4 py-2">{{ quizData.introduction }}</div> -->
      <div class="px-4 py-2">PEMBAHASAN</div>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <!-- <pre>
      {{ quizData }}
      {{ gameplays }}
      {{ reviewData }}
      {{ total_correct }}
      {{ total_point }}
    </pre> -->
    <MultipleChoice  
      v-if="type == 'multiple_choice'" 
      refetch
      :quizData="quizData"
      :gameplays="gameplays" 
      :reviewData="reviewData" 
      :total_correct="total_correct" 
      :total_point="total_point"/>
    <Jigsaw 
      v-if="type == 'jigsaw'" 
      :quizData="quizData"
      :gameplays="gameplays" 
      :reviewData="reviewData" 
      :total_correct="total_correct" 
      :total_point="total_point"/>
    <Pairing 
      v-if="type == 'matching_word'" 
      refetch
      :review="review"
      :quizData="quizData"
      :gameplays="gameplays" 
      :reviewData="reviewData" 
      :total_correct="total_correct" 
      :total_point="total_point"/>
    <WordSearch 
      v-if="type == 'finding_word'" 
      refetch
      :review="review"
      :quizData="quizData" 
      :gameplays="gameplays" 
      :reviewData="reviewData" 
      :total_correct="total_correct" 
      :total_point="total_point"/>
  </div>
</template>

<script>
import MultipleChoice from './pop-quiz/explanation/MultipleChoice.vue';
import Jigsaw from './pop-quiz/explanation/Jigsaw.vue';
import Pairing from './pop-quiz/explanation/Pairing.vue';
import WordSearch from './pop-quiz/explanation/WordSearch.vue';
export default {
  components: {
    MultipleChoice,
    Jigsaw,
    Pairing,
    WordSearch
  },
  props: {
    review: Boolean,
    type: {
      type: String,
      default: "",
    },
    quizData: {
      type: Object,
      default() {
        return {}
      }
    },
    showModal: {
      type: Boolean,
      default: false
    },
    gameplays: {
      type: Array,
      default() {
        return [];
      },
    },
    reviewData: {
      type: Object,
      default() {
        return {};
      },
    },
    total_correct: {
      type: Number,
      default: 0
    },
    total_point: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
  }),
  methods: {
    closeModal() {
      this.$emit('click:closeModal')
    }
  }
}
</script>