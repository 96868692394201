import axios from "axios";
import url from "./api";

const state = () => ({
	under_maintenance: null,
	announcement: null,
	phone_number: [
		"6282137194353", // Isa
		"6287758014542", // Rennis
		"6282217035188", // Linda
		"6285643302133", // Syihab
		"6282138525848", // Alvon
	],
	site_data: {}
});

const mutations = {
	setUnderMaintenance(state, data) {
		state.under_maintenance = data.under_maintenance;
	},
	setAnnouncement(state, data) {
		state.announcement = data.announcement;
	},
	setSiteData(state, data) {
		state.site_data = data
	}
};

const actions = {
	async getCompanySetting({ commit }) {
		const access_key = process.env.VUE_APP_ACCESS_KEY;

		return axios.get(url.company_setting.replace('{access_key}', access_key), {
			headers: { "Content-Type": "application/json" },
		})
		.then((res) => {
			let data = res.data.data;
			commit("setUnderMaintenance", data);
			commit("setAnnouncement", data);
			return(data)
		}).catch((err) => {
			console.log(err);
		});
	},
  async getSiteData({commit}) {
    return axios.get(url.detail_page).then(res => {
      commit('setSiteData', res.data.data)
    })
  }
}

export default { state, mutations, actions };