<template>
  <div>
    <div
      v-if="loading"
      class="row mb-3 justify-center"
      style="min-height: 400px"
    >
      <v-skeleton-loader class="mx-auto" height="300" type="image">
      </v-skeleton-loader>
    </div>
    <template v-else>
      <div class="pa-4">
        <quiz-pairing 
          :review="true"
          :refetch="true"
          :quizData="quizData" 
          :review-data="reviewData" />
        
        <v-divider></v-divider>
        <v-row>
          <v-col cols="6">
            <table style="width: 100%">
              <tbody>
                <tr>
                  <td class="pa-2">Total Benar</td>
                  <td class="pa-2 text-right">{{ total_correct }}</td>
                </tr>
                <tr>
                  <td class="pa-2">Total Point</td>
                  <td class="pa-2 text-right">{{ total_point }}</td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import QuizPairing from "../QuizPairing.vue";
export default {
  components: { QuizPairing },
  props: {
    review: Boolean,
    quizData: {
      type: Object,
      default() {
        return {};
      },
    },
    reviewData: {
      type: Object,
      default() {
        return {};
      },
    },
    gameplays: {
      type: Array,
      default() {
        return [];
      },
    },
    total_correct: {
      type: Number,
      default: 0,
    },
    total_point: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: false,
    questions: [],
    reviewData: {},
    total_correct: 0,
    total_point: 0,
    gameplay: {
      current: 1,
      list_question: [],
    },
  })
};
</script>