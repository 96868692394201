<template>
  <v-card elevation="0" class="px-2 transparent" @click="showCertif">
    <vue-html2pdf ref="certifPeserta" :enable-download="true" :preview-modal="false" :show-layout="true" :pdf-quality="2"
      :filename="certificateName" pdf-orientation="landscape" :manual-pagination="true" pdf-content-width="100%"
      @hasDownloaded="hasGenerated($event)">
      <section slot="pdf-content">
        <section class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/bg-new-certif.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
          <div class="style1" v-if="Object.keys(data).length > 0">
            <div style="text-align: center;margin-bottom: 15px;">
              <img src="@/assets/images/logo-dash.png" alt="" srcset="" style="width:200px" />
            </div>
            <div style="
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 26px;
                        line-height: 47px;
                        text-align: center;
                        letter-spacing: 0.015em;
                        margin-top: 10px;
                      ">
              Sertifikat Penyelesaian
            </div>
            <div style="
                        font-family: Roboto;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;
                        margin-top: 4px;
                      ">
              Diberikan atas keberhasilan mengikuti dan lulus dari Kelas <br>
              {{ data.program_name }} <br>Kepada
            </div>

            <div style="
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 900;
                        font-size: 32px;
                        line-height: auto;
                        text-align: center;
                        margin-top: 20px;
                        padding-bottom: 5px;
                      ">
              {{ data.user_name }}
            </div>
            <hr style="width: 35%; border: 1px solid rgba(0, 0, 0, 0.2); margin: auto;">
            <div style="
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 14px;
                      text-align: center;
                      margin-top: 10px;
                    ">
              <!-- <div style="margin-top: 5px">Dibawah bimbingan tenaga pelatih : {{ data.fasiltator[0].name }}</div> -->
              <div style="margin-top: 5px">atas keberhasilan menyelesaikan program selama {{ data.program.estimation_duration.hour }} jam</div>
            </div>
            <div v-if="data.feedback_score >= 80"
              style="font-size: 16px; font-family: Roboto; text-align: center; margin-top: 10px;">
              <span style="font-size: 18px; font-weight: 900; padding-right: 8px">LULUS</span>
              <span style="padding-right: 8px">dengan predikat</span>
              <span style="font-size: 18px; font-weight: 900;">"SANGAT BAIK"</span>
            </div>
            <template v-if="false">
              <div v-if="data.feedback_score >= 80"
                style="font-family: Roboto; font-weight: normal; margin-top: 5px; font-size: 14px; text-align: center;">
                dengan nilai akhir: {{ data.feedback_score }}
              </div>
            </template>
            <div style="width:80%;display: flex;margin-top: 45px;margin-left: auto;margin-right: auto;">
              <div style="width: 33%; margin: auto">
                <div style="
                          font-family: Roboto;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 14px;
                          text-align: center;
                        ">
                  <vue-qrcode :value="data.certificate_url"
                    :options="{ color: { dark: '#4B4B4B', }, width: 100 }"></vue-qrcode>
                  <div>{{ data.certificate_number }}</div>
                </div>
              </div>
              <div style="width: 33%;margin:0 5%;margin-right: 0;">
                <div style="
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 16px;
                            text-align: center;
                            margin-bottom: 5px;
                            /*margin-top: 30px;*/
                          ">
                  Yogyakarta,
                  {{ currentDateTime(data.end_test_at) }}
                </div>
                <div style="
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 14px;
                            text-align: center;
                            /*margin-top: 29px;*/
                          ">
                  Director,
                </div>
                <div style="margin-top: 10px; text-align: center">
                  <img width="50%" src="@/assets/images/ttd-ytc.png" alt="" srcset="" />
                </div>
                <div style="
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 14px;
                            text-align: center;
                            margin-top: 6px;
                          ">
                  M. Animna Husna, S.I.A.
                </div>
              </div>
              <div style="width: 33%; margin: auto auto auto 3.2rem;">
                <div style="
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                          ">
                  <div v-if="this.data.is_prakerja === true">
                    <p style="margin-bottom: 10px;" :style="{textAlign: showOtherLogo ? 'center' : 'left'}">Diverifikasi oleh : </p>
                    <div style="text-align: center">
                      <template v-if="showOtherLogo">
                        <img class="pa-1" v-if="showDigimind" src="@/assets/images/digimind-logo.png" alt="" style="vertical-align: middle; width: 40%"/>
                        <img class="pa-1" v-if="showIoa" src="@/assets/images/logo-ioa.jpg" alt="" style="vertical-align: middle; width: 50%"/>
                        <!-- <img class="pa-1" v-if="showIoa" src="@/assets/images/logo-ioa-abadi.jpg" alt="" style="vertical-align: middle; width: 50%"/> -->
                        <img class="pa-1" v-if="showAidia" src="@/assets/images/logo-aidia.png" alt="" style="vertical-align: middle; width: 30%"/>
                        <img class="pa-1" v-if="showAdgi" src="@/assets/images/logo-adgi.jpg" alt="" style="vertical-align: middle; width: 30%"/>
                        <img class="pa-1" v-if="showIbcsd" src="@/assets/images/logo-ibcsd.png" alt="" style="vertical-align: middle; width: 40%"/>
                        <img class="pa-1" v-if="showAprindo" src="@/assets/images/logo-aprindo.jpg" alt="" style="vertical-align: middle; width: 30%"/>
                        <img class="pa-1" src="@/assets/images/prakerja-new.png" alt="" style="vertical-align: middle; width: 50%"/>
                      </template>
                      <img v-else class="mr-2" src="@/assets/images/prakerja-new.png" alt="" srcset="" :style="{width: '60%'}" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="data.is_prakerja">
          <section v-if="data.program_name.toLowerCase() == 'Bahasa Inggris Untuk Karyawan Perkantoran'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Bahasa Inggris Untuk Karyawan Perkantoran.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Menggunakan Bahasa Inggris Bagi Calon Sekretaris'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Menggunakan Bahasa Inggris Bagi Calon Sekretaris.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Dasar Microsoft Excel untuk Administrasi Perkantoran'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Dasar Microsoft Excel Untuk Administrasi Perkantoran.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Editing Video di Capcut untuk Content Creator'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Editing Video di Capcut untuk Content Creator.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Interview Melamar Kerja untuk Menjadi Staff Umum'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Interview Melamar Kerja untuk Menjadi Staff Umum.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Keterampilan Berkomunikasi Dengan Pelanggan Untuk Kasir'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Keterampilan Berkomunikasi Dengan Pelanggan Untuk Kasir.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Membuat Konten Sosial Media Untuk Marketing Online'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Membuat Konten Sosial Media untuk Marketing Online.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Membuat Laporan Keuangan dengan Microsoft Excel'.toLowerCase()" 
            class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Membuat Laporan Keuangan dengan Microsoft Excel.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Membuat Website Toko Online'.toLowerCase()" 
            class="pdf-item"
            :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Membuat Website Toko Online.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Menggunakan Bahasa Inggris Bagi Staf Admin'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Menggunakan Bahasa Inggris Bagi Staf Admin.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Menulis Konten SEO untuk Copywriter'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Menulis Konten SEO untuk Copywriter.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Optimalisasi FB Ads untuk Digital Marketing Manager'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Optimalisasi FB Ads untuk Digital Marketing Manager.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Pelayanan Prima untuk Penjaga Toko'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Pelayanan Prima untuk Penjaga Toko.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Perencanaan Iklan Media Sosial untuk Manajer Periklanan'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Perencanaan Iklan Media Sosial untuk Manajer Periklanan.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Strategi Iklan Digital Marketing Online'.toLowerCase()" 
            class="pdf-item"
            :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Strategi Iklan Digital Marketing Online.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Strategi Kompensasi dan Benefit Karyawan Untuk HR Payroll'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Strategi Kompensasi dan Benefit Karyawan Untuk HR Payroll.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Strategi Komunikasi Pelanggan untuk Customer Service'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Strategi Komunikasi Pelanggan Untuk Customer Service.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Strategi Penjualan Minuman Kekinian'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Strategi Penjualan Minuman Kekinian.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Dasar Keselamatan dan Kesehatan Kerja di Tempat Kerja'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Dasar Keselamatan dan Kesehatan Kerja di Tempat Kerja.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Mengelola Dokumen Untuk Staf Perkantoran Umum'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Mengelola Dokumen Untuk Staf Perkantoran Umum.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Pelayanan Prima untuk Customer Service Online'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Pelayanan Prima untuk Customer Service Online.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Layanan Pelanggan Berbasis Digital'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Layanan Pelanggan Berbasis Digital.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Kepuasan Pelanggan untuk Kasir'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Kepuasan Pelanggan untuk Kasir.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Pelayanan Pelanggan Untuk Customer Service'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Pelayanan Pelanggan Untuk Customer Service.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Mengelola Gaji Karyawan Dengan Microsoft Excel'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Mengelola Gaji Karyawan Dengan Microsoft Excel.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Pelayanan Prima Untuk Staf Toko'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Pelayanan Prima Untuk Staf Toko.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Bahasa Inggris untuk Karyawan'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Bahasa Inggris untuk Karyawan.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Microsoft Excel Untuk Staf Admin'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Microsoft Excel Untuk Staf Admin.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Desain Logo Dan Konten Untuk Desainer Grafis'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Desain Logo Dan Konten Untuk Desainer Grafis.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Merancang Desain Konten Untuk Desainer Grafis'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Merancang Desain Konten Untuk Desainer Grafis.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Strategi Pemasaran Untuk Spesialis Pengembangan Bisnis'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Strategi Pemasaran Untuk Spesialis Pengembangan Bisnis.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Merumuskan Kebijakan Organisasi dan Strategi Pengelolaan SDM Untuk Menjadi Manajer Sumber Daya Manusia'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Merumuskan Kebijakan Organisasi dan Strategi Pengelolaan SDM Untuk Menjadi Manajer Sumber Daya Manusia.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Merencanakan Strategi Pemasangan Iklan di Media Sosial Untuk Menjadi Manajer Periklanan'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Merencanakan Strategi Pemasangan Iklan di Media Sosial Untuk Menjadi Manajer Periklanan.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Mengembangkan Konten di Media Sosial Untuk Menjadi Spesialis Pemasaran Digital'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Mengembangkan Konten di Media Sosial Untuk Menjadi Spesialis Pemasaran Digital.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Menyusun Laporan Keuangan dengan Microsoft Excel untuk Menjadi Staf Akuntan'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Menyusun Laporan Keuangan dengan Microsoft Excel untuk Menjadi Staf Akuntan.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Melakukan Riset Kata Kunci dan Optimasi SEO Website Untuk Menjadi Manajer Pemasaran'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Melakukan Riset Kata Kunci dan Optimasi SEO Website Untuk Menjadi Manajer Pemasaran.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Mengolah Data Penjualan dengan Microsoft Excel untuk Menjadi Admin Input Data'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Mengolah Data Penjualan dengan Microsoft Excel untuk Menjadi Admin Input Data.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Pengelolaan Akuntansi Dan Perpajakan Bagi Akuntan Untuk UMKM'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Pengelolaan Akuntansi Dan Perpajakan Bagi Akuntan Untuk UMKM.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Melakukan Komunikasi Bahasa Inggris Melalui Panggilan Telepon Bagi Calon Petugas Informasi Pusat Kontak'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Melakukan Komunikasi Bahasa Inggris Melalui Panggilan Telepon Bagi Calon Petugas Informasi Pusat Kontak.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Mengelola Kinerja Karyawan Untuk Menjadi Manajer Sumber Daya Manusia'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Mengelola Kinerja Karyawan Untuk Menjadi Manajer Sumber Daya Manusia.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Menyusun Surat Bisnis Dalam Bahasa Inggris Bagi Calon Sekretaris'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Menyusun Surat Bisnis Dalam Bahasa Inggris Bagi Calon Sekretaris_1.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Bahasa Inggris Untuk Administrasi Dan Legalitas Perkantoran'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Bahasa Inggris Untuk Administrasi Dan Legalitas Perkantoran.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Menghitung Tunjangan Di Excel Untuk HR'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Menghitung Tunjangan Di Excel Untuk HR.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Komunikasi Efektif Dan Negosiasi Bagi Staf Penjualan'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Komunikasi Efektif Dan Negosiasi Bagi Staf Penjualan.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Menghitung Gaji Bersih Di Excel Untuk HR'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Menghitung Gaji Bersih Di Excel Untuk HR.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'Pengelolaan Sampah Organik dan Plastik Rumah Tangga'.toLowerCase()"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Pengelolaan Sampah Organik dan Plastik Rumah Tangga.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else-if="data.program_name.toLowerCase() == 'membuat database aplikasi untuk database administrator'"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/Membuat Database Aplikasi Untuk Database Administrator.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/bg-new-certif.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
            <div v-if="Object.keys(data).length > 0" style="padding-top: 200px;">
              <div style="text-align: center;margin-bottom: 15px;">
                <img src="@/assets/images/logo-dash.png" alt="" srcset="" style="width:200px" />
              </div>
              <div style="
                          font-family: Roboto;
                          font-style: normal;
                          font-weight: bold;
                          font-size: 26px;
                          line-height: 47px;
                          text-align: center;
                          letter-spacing: 0.015em;
                          margin-top: 10px;
                        ">
                KOMPETENSI YANG DILATIH
              </div>
              <div style="
                          font-family: Roboto;
                          font-weight: normal;
                          font-size: 16px;
                          line-height: 20px;
                          text-align: center;
                          margin-top: 4px;
                        ">
                {{ data.program_name }}
              </div>
              <div style="
                          font-family: Roboto;
                          font-style: normal;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 16px;
                          text-align: left;
                          margin-top: 30px;
                          display: flex;
                          justify-content: center
                        ">
                <div>
                  <p style="font-weight: bold; font-size: 16px; text-align: center; margin-bottom: 10px; margin-top: 0px;">
                    Kompetensi</p>
                  <div style="padding-left: 0px; margin-left: 0px;" v-for="(item, index) in data.competence" :key="index">
                    <p style="margin-bottom: 2px;font-size:14px;">
                      {{ index + 1 }}. {{ item }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <template v-else>
          <section v-if="data.program_name.toLowerCase() == 'mengolah dokumen dengan ms. word & ms. excel'"
            class="pdf-item" :style="{
              backgroundImage: `url('${require('@/assets/images/certificate/mengolah dokumen dengan ms. word & ms. excel.png')}')`,
              backgroundSize: `100% 100%`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              width: `100%`,
              height: `790px`,
              color: `#4B4B4B`,
            }">
          </section>
          <section v-else class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/bg-new-certif.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
            <div v-if="Object.keys(data).length > 0" style="padding-top: 200px;">
              <div style="text-align: center;margin-bottom: 15px;">
                <img src="@/assets/images/logo-dash.png" alt="" srcset="" style="width:200px" />
              </div>
              <div style="
                          font-family: Roboto;
                          font-style: normal;
                          font-weight: bold;
                          font-size: 26px;
                          line-height: 47px;
                          text-align: center;
                          letter-spacing: 0.015em;
                          margin-top: 10px;
                        ">
                KOMPETENSI YANG DILATIH
              </div>
              <div style="
                          font-family: Roboto;
                          font-weight: normal;
                          font-size: 16px;
                          line-height: 20px;
                          text-align: center;
                          margin-top: 4px;
                        ">
                {{ data.program_name }}
              </div>
              <div style="
                          font-family: Roboto;
                          font-style: normal;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 16px;
                          text-align: left;
                          margin-top: 30px;
                          display: flex;
                          justify-content: center
                        ">
                <div>
                  <p style="font-weight: bold; font-size: 16px; text-align: center; margin-bottom: 10px; margin-top: 0px;">
                    Kompetensi</p>
                  <div style="padding-left: 0px; margin-left: 0px;" v-for="(item, index) in data.competence" :key="index">
                    <p style="margin-bottom: 2px;font-size:14px;">
                      {{ index + 1 }}. {{ item }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </template>
      </section>
    </vue-html2pdf>
  </v-card>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import VueQrcode from '@chenfengyuan/vue-qrcode';
// import { mapGetters } from "vuex";

export default {
  name: "certif-list",
  components: { VueHtml2pdf, VueQrcode },
  data() {
    return {
      certificateName: '',
      dialog: true,
      showbtn: false,
      false: false,
      output: null,
      tampil: false,
      is_prakerja: null,
      data: {},
      test: '10',
      member: {},
      program: '',
      competence: [],
      dialogCertif: false,
      styleObject: {
        paddingTop: '100px',
        paddingBottom: '180px'
      },
      styleObject2: {
        paddingTop: '100px',
        paddingBottom: '100px'
      },
      styleObject3: {
        paddingTop: '100px',
        paddingBottom: '80px'
      },
      timer: '',
      score: '',
      dateFinish: [],
      tgl: null
    };
  },
  computed: {
    showOtherLogo() {
      return this.showDigimind || this.showIoa || this.showAdgi || this.showAidia || this.showIbcsd || this.showAprindo
    },
    showDigimind() {
      const programs = [
        'mengembangkan konten di media sosial untuk menjadi spesialis pemasaran digital',
        'melakukan riset kata kunci dan optimasi seo website untuk menjadi manajer pemasaran',
        'merencanakan strategi pemasangan iklan di media sosial untuk menjadi manajer periklanan'
      ]
      return programs.includes(this.data.program_name.toLowerCase())
    },
    showAprindo() {
      const programs = [
        'pelayanan prima untuk staf toko',
        'kepuasan pelanggan untuk kasir'
      ]
      return programs.includes(this.data.program_name.toLowerCase())
    },
    showIoa () {
      const programs = [
        'microsoft excel untuk staf admin',
        'menggunakan bahasa inggris bagi calon sekretaris',
        'menyusun surat bisnis dalam bahasa inggris bagi calon sekretaris',
        'bahasa inggris untuk karyawan',
        'mengelola gaji karyawan dengan microsoft excel',
        'mengolah data penjualan dengan microsoft excel untuk menjadi admin input data',
        'mengelola kinerja karyawan untuk menjadi manajer sumber daya manusia',
        'melakukan komunikasi bahasa inggris melalui panggilan telepon bagi calon petugas informasi pusat kontak',
        'merumuskan kebijakan organisasi dan strategi pengelolaan sdm untuk menjadi manajer sumber daya manusia'
      ]
      return programs.includes(this.data.program_name.toLowerCase())
    },
    showAidia() {
      const programs = [
        'desain logo dan konten untuk desainer grafis',
      ]

      return programs.includes(this.data.program_name.toLowerCase())
    },
    showAdgi() {
      const programs = [
        'desain logo dan konten untuk desainer grafis',
        'merancang desain konten untuk desainer grafis',
      ]

      return programs.includes(this.data.program_name.toLowerCase())
    },
    showIbcsd() {
      const programs = [
        'dasar keselamatan dan kesehatan kerja di tempat kerja'
      ]

      return programs.includes(this.data.program_name.toLowerCase())
    }

  },
  created() {
    this.getData();
    // this.timer = setInterval(this.getData, 2000)
  },
  mounted() {
  },
  methods: {
    ganti() {
      const programs = [
        'mengembangkan konten di media sosial untuk menjadi spesialis pemasaran digital',
        'mengelola gaji karyawan dengan microsoft excel',
        'desain logo dan konten untuk desainer grafis',
        'merancang desain konten untuk desainer grafis',
        'dasar keselamatan dan kesehatan kerja di tempat kerja'
      ]

      const index = programs.findIndex(v => v == this.data.program_name.toLowerCase())
      const program_name = programs[index +1 < programs.length ? index + 1 : 0]
      this.$set(this.data, 'program_name',program_name)
    },
    getData() {
      this.false = true;
      this.axios
        // .get(`users/v1/member_redeem/detail/${this.$route.params.id}`,{headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }})
        .get(`users/v1/public/order/detail/${this.$route.params.id}`, { headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY } })
        .then((response) => {
          let res = response.data.data;
          this.data = res;
          this.certificateName = res.user_name + ' - ' + res.program_name
          setTimeout(() => {
            this.showCertif();
          }, 200)
        });
    },
    cancelAutoUpdate() { clearInterval(this.timer) },
    reloadPage() {
      window.location.reload();
      this.showbtn = true;
    },
    currentDateTime(a) {
      return moment(a).lang("nl").format("L");
    },
    currentDateTimes(a) {
      return moment(a).lang("nl").format("L");
    },
    dateBirthTest(a) {
      return a;
    },
    convertHour(val) {
      let minutes = 0
      let hours = 0
      let last = ''
      if (val > 60) {
        hours = val / 60
        minutes = val % 60
        if (minutes > 0) {
          last = parseInt(hours) + ' Jam ' + minutes + ' Menit'
        } else {
          last = hours + ' Jam '
        }
      } else {
        minutes = val
        last = minutes + ' Menit'
      }
      return last
    },
    dateExpired(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, "Y");
      return moment(futureYear).format("MMMM D, YYYY");
    },
    updateCertif(id) {
      let order_id = this.details.id;
      let sertificate_id = id;

      this.axios
        .put(
          `/users/v1/member/order/update-sertificate`,
          { order_id, sertificate_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            // this.members = res;
            this.getDetailCertif(sertificate_id);
            // console.log("berhasil", res);
          }
        });
    },
    hasGenerated() {
      // console.log($event);
    },
    getDetail(order) {
      this.id = order;
      this.axios
        .get(`/users/v1/myprogram/detail/${this.id}`)
        .then((response) => {
          // this.loading = false;
          let res = response.data.data;
          // this.loading = false;
          this.detail = res.program;
          // console.log('data detail', this.detail)
          this.showCertif(this.id);
        });
    },
    showCertif() {
      this.$refs.certifPeserta.generatePdf();
      // window.location.reload();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

tr:nth-child(even) {
  background-color: #f9fafc;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr {
  color: #3c4858;
}

.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}

.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 20px;
}

.vue-html2pdf .layout-container[data-v-1fd3ad26] {
  background: #fff !important;
}

.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}

.style1 {
  padding-top: 100px;
  padding-bottom: 188px
}

.style2 {
  padding-top: 100px;
  padding-bottom: 180px;
}

.style3 {
  padding-top: 100px;
  padding-bottom: 148px;
}

.style4 {
  padding-top: 100px;
  padding-bottom: 116px;
}

.style5 {
  padding-top: 100px;
  padding-bottom: 0px;
}

@media print {
  @page {
    size: landscape;
  }
}
</style>