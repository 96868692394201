<template>
  <div>
   <!-- <div class="px-4 py-2">Mini Quiz. Jawab Pertanyaan Dibawah Ini!</div> -->
   <v-divider></v-divider>
   <div class="pa-4">
     <!-- navigation -->
     <div class="d-flex justify-space-between mb-4">
       <div class="d-flex align-center">
         <v-btn width="48" min-width="48" outlined small color="primary"  :disabled="gameplay.current <= 1" @click="beforeQuestion()">
           <v-icon>$prev</v-icon>
         </v-btn>
         <div class="mx-4">
           Soal no
           <span class="primary--text">{{ this.gameplay.current }}</span>
           dari
           {{ gameplays.length }}
         </div>
         <v-btn width="48" min-width="48" small outlined color="primary" :disabled="gameplay.current == gameplays.length"  @click="nextQuestion()">
           <v-icon>$next</v-icon>
         </v-btn>
       </div>
     </div>
     <!-- end navigation -->


     <!-- {{ currentQuestion }} -->

     <div class="d-flex mb-4">
        <div class="flex-grow-0 mr-4 text-h6" style="width: 2rem">
          <div>{{ gameplay.current }}.</div>
        </div>
        <div class="flex-grow-1">
          <div v-if="currentQuestion.gameplay.question.audio">
            <audio controls>
              <source :src="currentQuestion.gameplay.question.audio">
            </audio>
          </div>
          <div v-if="currentQuestion.gameplay.question.image">
            <img style="height: 300px;" :src="currentQuestion.gameplay.question.image" :title="currentQuestion.gameplay.question.text"/>
          </div>
          <div class="mb-4 text-h6" v-html="currentQuestion.gameplay.question.text"></div>
          <div class="d-flex flex-column align-start" v-if="!review">
            <v-btn
              class="pa-4 flex-grow-0 mb-4"
              :outlined="currentQuestion.answer.answer[0] == option.text ? false : true"
              v-for="(option, index) in currentQuestion.gameplay.options"
              :color="currentQuestion.answer.answer[0] == option.text ? 'primary' : ''"
              :key="option.text"
              :value="option.text"
              style="text-transform: none">
              <span class="mr-2 "> {{ (index + 10).toString(36) }}. </span>
              <span class="">{{ option.text }}</span>
              <!-- <v-img class="mr-2" contain :src="option.image" alt=""></v-img> -->
            </v-btn>
          </div>

          <v-card outlined> 
            <div class="mx-2 pa-4">
              <div>Jawaban Anda <strong>{{ currentQuestion.total_correct ? '' : 'Tidak' }} Tepat</strong></div>
              <div v-if="!currentQuestion.total_correct">Jawaban yang tepat adalah <strong>{{ currentQuestion.gameplay.correct_answer.join(',') }}</strong> </div>
            </div>
          </v-card>
        </div>
      </div>

      <v-divider></v-divider>
      <v-row>
        <v-col cols="6">
          <table style="width: 100%">
            <tbody>
              <tr>
                <td class="pa-2">Total Benar</td>
                <td class="pa-2 text-right">{{ total_correct }}</td>
              </tr>
              <tr>
                <td class="pa-2">Total Point</td>
                <td class="pa-2 text-right">{{ total_point }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>


   </div>
 </div>
</template>

<script>
export default {
  props: {
    review: Boolean,
    quizData: {
      type: Object,
      default() {
        return {}
      }
    },
    gameplays: {
      type: Array,
      default() {
        return [];
      },
    },
    reviewData: {
      type: Object,
      default() {
        return {};
      },
    },
    total_correct: {
      type: Number,
      default: 0
    },
    total_point: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    isLoading: false,
    gameplay: {
      current: 1,
      list_question: [],
    },
  }),
  computed: {
    currentQuestion() {
      try {
        const index = this.gameplay.current - 1;
        return this.gameplays[index];
      } catch (error) {
        return {};
      }
    },
  },
  methods: {
    async setQuestions () {

      await this.axios
      .post(`/gameplay/v1/detail-answer/${this.quizData.license}/${this.quizData.topic_id}/${this.quizData.activity_id}`)
      .then((res) => res.data)
      .then(async (res) => {
        this.$set(this.gameplay, "current", 1);
        // this.$set(this.gameplay, "list_question", res.data);

        this.questions = res.data.gameplays
        this.total_correct = res.data.total_correct
        this.total_point = res.data.total_point
      })
    },
    beforeQuestion() {
      this.gameplay.current--
    },
    nextQuestion() {
      this.gameplay.current++
    },
  }
}
</script>