<template>
  <div>
    <hr style="border-color: transparent" />
    <section class="mt-5 mb-5 white px-10 py-7 text-justify">
      <!-- <h1 class="text-h4 col-md-10 mx-auto">{{ title }}</h1> -->
      <div id="content" v-html="content" class="my-4 col-md-10 mx-auto"></div>
    </section>
    
    <!-- <hr style="border-color: transparent" /> -->
    <!-- <section class="mt-5 mb-5 white">
      <div class="col-md-10 mx-auto">
        <img
          src="@/assets/images/KV-Modal Siap Kerja.png"
          class="img-kategori"
        />
        <p class="fs-24 fs-xs-14 font-weight-bold my-2 text-center">
          Raih kesempatan mendapatkan “Modal Siap Kerja” dengan beli dan selesaikan pelatihan Kartu Prakerja di YEC minimal 2x pelatihan!
        </p>
        <p class="fs-20 fs-xs-12 my-3 text-center">
          Membantu mendorong peserta untuk membeli dan menyelesaikan pelatihan kartu prakerja, dan memotivasi mereka untuk siap kerja!
        </p>
        <p class="fs-20 fs-xs-12 my-3 text-center">
          Total <strong>Modal Siap Kerja</strong> periode <b>8 Mei - 8 Agustus 2024</b> sebesar <b>Rp 500.000.000 </b> 
          untuk <b>2000+ Peserta Per 2 Minggu</b> yang sudah memenuhi syarat dan ketentuan yang berlaku
        </p>
        

        <div v-for="(syarat, i) in syaratKetentuan" :key="i">
          <p class="fs-24 fs-xs-16 font-weight-bold my-2">
            {{ syarat.title }}
          </p>
          <ol class="fs-16 fs-xs-12 my-2">
            <li v-for="(content, j) in syarat.content" :key="j" class="my-3">
              <p v-if="content.title" v-html="content.title"></p>
              <ol type="a">
                <li v-for="(item, k) in content.content" :key="k" class="my-2" v-html="item"></li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: "public-prakerja",
  components: {},
  data: () => ({
    title: "",
    content: "",
    // syaratKetentuan: [
    //   {
    //     title: "Syarat dan Ketentuan",
    //     content: [
    //       {
    //         title: "Untuk berpartisipasi dalam Modal Siap Kerja ini, kamu wajib:",
    //         content: [
    //           "Beli dan selesaikan minimal 2 pelatihan dengan total minimal Rp 2.000.000 di Platform Yec.co.id selama periode 8 Mei - 8 Agustus 2024",
    //           "Memiliki sisa bantuan pelatihan maksimal 50% dari jumlah bantuan pelatihan",
    //           "Mendapatkan sertifikat penyelesaian."
    //         ],
    //       },
    //       {
    //         title: "Jika membeli dan menyelesaikan 2 Pelatihan Mininal Rp 2.000.000 dapatkan Modal Siap kerja sebesar:",
    //         content: [
    //           `<b>Beli dan selesai 2 pelatihan pembelajaran mandiri (nonton video/spl) dapatkan modal Rp 500.000 untuk 750+ peserta per 2 minggu</b>`,
    //           `<b>Beli dan selesai 2 pelatihan : 1 Pelatihan webinar dan 1 pelatihan mandiri dapatkan modal Rp 550.000 untuk 750+ peserta per 2 minggu</b>`,
    //           `<b>Beli dan selesai 2 pelatihan webinar (live teaching) dapatkan modal Rp 600.000 untuk 500+ peserta per 2 minggu</b>`,
    //         ],
    //       },
    //       {
    //         title: "Jika membeli dan menyelesaikan 3 Pelatihan minimal Rp 3.000.000 dapatkan Modal Siap Kerja Sebesar:",
    //         content: [
    //           `<b>Beli dan selesai 3 pelatihan pembelajaran mandiri (nonton video/spl) dapatkan modal Rp700.000 untuk 1000+ peserta per 2 minggu</b>`,
    //           `<b>Beli dan selesai 3 pelatihan : 1 Pelatihan webinar dan 2 pelatihan mandiri dapatkan modal Rp850.000 untuk 1000+ peserta per 2 minggu</b>`,
    //         ],
    //       },
    //       {
    //         title: `<b>Extra Tambahan Modal siap kerja khusus Untuk 200 orang pertama perbulannya yang membeli pelatihan melalui Bukalapak, akan mendapatkan :</b>`,
    //         content: [
    //           `<b>Tambahan saldo e-wallet senilai Rp75.000, untuk pembelian 2 pelatihan di Bukalapak</b>`,
    //           `<b>Tambahan saldo pelatihan senilai Rp125.000, untuk pembelian 3 pelatihan di bukalapak</b>`,
    //           `<b>Perhitungan 200 orang pertama, terhitung mulai dari user yang sudah membeli pelatihan per 8 Mei 2024, dan mengisi form modal siap kerja per tanggal 17 Mei 2024</b>`,
    //         ],
    //       },
    //       {
    //         title: "Pastikan akun e-wallet sudah di upgrade, dan nama akun e-wallet sesuai dengan nama yang terdaftar di prakerja.",
    //         content: null,
    //       },
    //       {
    //         title: `Peserta wajib mengisi form kepesertaan melalui link <a href="https://bit.ly/KepesertaanModalSiapKerjaYEC">https://bit.ly/KepesertaanModalSiapKerjaYEC</a>`,
    //         content: null,
    //       },
    //       {
    //         title: `Peserta wajib mengikuti Webinar Superadmin yang di selenggarakan oleh YEC, link penyelenggaran Webinar terlampir di  <a href="https://bit.ly/KepesertaanModalSiapKerjaYEC">https://bit.ly/KepesertaanModalSiapKerjaYEC</a>`,
    //         content: null,
    //       },
    //       {
    //         title: "Peserta wajib screenshoot keikutsertaan dalam webinar superadmin YEC lalu di lampirkan di Form Kepesertaan",
    //         content: null,
    //       },
    //       {
    //         title: "Peserta bisa mengklaim modal siap kerja jika sudah 2 pelatihan atau klaim setelah 3 pelatihan selesai",
    //         content: null,
    //       },
    //       {
    //         title: "Penerima Modal siap kerja akan diumumkan melalui email oleh tim YEC.CO.ID setiap 2 minggu sekali",
    //         content: null,
    //       },
    //       {
    //         title: `Follow dan Like salah satu postingan video di tiktok <a href="https://www.tiktok.com/@prakerjayec?is_from_webapp=1&sender_device=pc" target="_blank">@prakerjayec</a>.`,
    //         content: null,
    //       }
    //     ],
    //   },
    //   {
    //     title: "Syarat dan Ketentuan Lainnya:",
    //     content: [
    //       {
    //         title: "Bagi nomor yang tidak terdaftar, Yec berhak membatalkan pemberian saldo e-wallet",
    //         content: null,
    //       },
    //       {
    //         title: "Pastikan no. HP yang dicantumkan sudah benar, aktif, dan terdaftar",
    //         content: null,
    //       },
    //       {
    //         title: "Saldo e-wallet akan dikirimkan selambat-lambatnya 3 hari kerja terhitung dari pengumuman penerima modal siap kerja",
    //         content: null,
    //       },
    //       {
    //         title: "Yec berhak membatalkan pemberian modal siap kerja jika peserta tidak memenuhi syarat dan ketentuan atau ditemukan ketidaksesuaian informasi/data yang diberikan peserta",
    //         content: null,
    //       },
    //       {
    //         title: "Tim Yec.co.id hanya akan menghubungi Penerima Modal siap kerja yang berhak mendapat saldo e-wallet melalui email",
    //         content: null,
    //       }
    //     ],
    //   },
    // ],  
  }),
  created() {},
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const response = await this.axios.get(`/company/v1/public/company-page/${process.env.VUE_APP_ACCESS_KEY}/modal-siap-kerja`)
        this.title = response.data.data.title
        this.content = response.data.data.content
      } catch (error) {
        console.log(error)
      }
    }
  },
};
</script>

<style>
/* #tab-ver .v-list-item-group .v-list-item--active {
  color: #f05326;
  border-left: 2px solid;
}
.sticky-sidebar {
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
} */
#content img {
  width: 100%;
  object-fit: cover;
  height: auto;
}
</style>