<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <div class="">
        <v-main class="grey">
          <v-app-bar id="menu-bar" class="white" v-show="show_sidebar" flat fixed>            
            <v-toolbar-title>
              <div class="my-1 text-left">
                <!-- src="@/assets/images/logo-dash.png" -->
                <img
                  :src="header_logo"
                  alt="Avatar"
                  class="img-yec-test"
                  @click="beranda()"
                  style="cursor: pointer;"
                />
              </div>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn @click="beranda()" class="me-2 transparent primary--text d-none d-md-block" elevation="0" >
              Beranda
            </v-btn>
            <v-btn @click="siapKerja()" class="me-2 transparent primary--text d-none d-md-block" elevation="0" >
              Modal Siap Kerja
            </v-btn>
            <v-btn @click="prakerja()" class="me-2 transparent primary--text d-none d-md-block" elevation="0" >
              FAQ Kelas Prakerja
            </v-btn>
            <v-btn @click="toPrivacy()" v-if="is_status_privacy_policy" class="me-2 transparent primary--text d-none d-md-block" elevation="0" >
              Privacy
            </v-btn>
            <v-btn @click="signIn()" class="me-2 transparent primary--text d-none d-md-block" elevation="0" >
              Masuk
            </v-btn>

            <v-btn @click="signUp()" color="primary" class="rounded-xl d-none d-md-block px-8" small elevation="0">
              Daftar
            </v-btn>
            <v-app-bar-nav-icon
              v-if="!$vuetify.breakpoint.mdAndUp"
              @click="dialog.menu = true"
            >
            </v-app-bar-nav-icon>
            <v-dialog
              v-model="dialog.menu"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar
                >
                  <v-toolbar-title>
                    <div class="my-1 text-left">
                      <img
                        src="@/assets/images/logo-dash.png"
                        alt="Avatar"
                        class="img-yec-test"
                      />
                    </div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                      icon
                      dark
                      @click="dialog.menu = false"
                      color="primary"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer></v-spacer>
                <v-card-text class="pa-5">
                  <v-btn @click="signUp();dialog.menu = false" color="primary" class="rounded-xl mb-4" block elevation="0">
                    Daftar
                  </v-btn>
                  <v-btn @click="signIn();dialog.menu = false" class=" transparent primary--text" block elevation="0" >
                    Masuk
                  </v-btn>
                  <v-btn @click="siapKerja();dialog.menu = false" class=" transparent primary--text" block elevation="0" >
                    Modal Siap Kerja
                  </v-btn>
                  <v-btn @click="prakerja();dialog.menu = false" class=" transparent primary--text" block elevation="0" >
                    FAQ Kelas Prakerja
                  </v-btn>
                  <v-btn @click="toPrivacy();dialog.menu = false" v-if="is_status_privacy_policy" class=" transparent primary--text" block elevation="0" >
                    Privacy
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-app-bar>

          <v-toolbar 
            color="primary"
            v-if="show_sidebar && $store.state.company.announcement.is_show"
            flat
            style="position: fixed;margin-top: 64px;width: 100%;z-index: 2;"
            class="d-flex justify-center align-center white white--text pt-4 fs-16">
            <div v-html="$store.state.company.announcement.text"></div>
          </v-toolbar>

          <v-container fluid :style="$store.state.company.announcement.is_show && show_sidebar ? 'margin-top: 128px;padding: 0px;': $route.name == 'login' || $route.name == 'registration' ? '' : 'margin-top: 64px;padding: 0px;'">
            <transition name="fade">
              <router-view v-if="loaded" :key="$route.fullPath"></router-view>
            </transition>
          </v-container>

          <div id="footer" class="py-3 white--text" style="background: #FF6600;" v-show="show_sidebar">
            <div class="container">
              <div class="row">
                <div class="col-md-4 text-justify">
                  <!-- src="@/assets/images/logo-dash-white.png" -->
                  <img
                    :src="footer_logo"
                    alt="Avatar"
                    class="mb-2 img-yec-test"
                  />
                  <div class="pe-5">
                    <p class="fs-16 fs-xs-14" style="white-space: break-spaces">{{ site_data.short_description }}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <p class="fs-24 fs-xs-20 font-weight-bold mb-4">Alamat Kantor</p>

                  <div v-for="address in site_data.addresses" :key="address.title">
                    <p class="fs-18 fs-xs-16 font-weight-regular mb-2">{{address.title}}</p>
                    <div class="pe-5 mb-4" >
                    <p class="fs-16 fs-xs-14 mb-0" style="white-space: break-spaces">{{address.address}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <p class="fs-24 fs-xs-16 font-weight-bold mb-2">Temukan Kami</p>
                  
                  <div v-for="(item, i) in Object.keys(companyContacts)" :key="i">
                    <div v-if="companyContacts[item].label" class="mb-2">
                      <a class="d-flex align-center white--text" :href="companyContacts[item].link" target="_blank">
                        <v-icon v-if="item != 'tiktok'" size="30" color="white" class="mr-2">{{ renderIcon(item) }}</v-icon>
                        <img v-else :src="require('@/assets/images/icon_tiktok.svg')" alt="icon tiktok" width="24" class="mr-4">
                        <div>{{ companyContacts[item].label }}</div>
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </v-main>
      </div>
    </v-app>
  </transition>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: false,
      group:null,
      notifications: [1],
      pageTitle: "",
      // title:false,
      pathnya: "",
      dialog:{
        menu:false,
      },
      announcement: {},
      is_status_privacy_policy: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapState({
      site_data: state => state.company.site_data
    }),
    header_logo() {
      return this.site_data.logo?.url;
    },
    footer_logo() {
      return this.site_data.footer_logo?.url;
    },
    companyContacts() {
      // make sort data contact to be this order : website, instagram, youtube, email, tiktok, facebook, telegram, whatsapp
      let contact = this.site_data.contact;
      let sortedContact = {};
      let order = ['website', 'instagram', 'youtube', 'email', 'tiktok', 'facebook', 'telegram', 'whatsapp'];
      order.forEach((key) => {
        if (contact[key]) {
          sortedContact[key] = contact[key];
        }
      });
      return sortedContact;
    },
    show_sidebar() {
      return !/\/auth\/.\.*/.test(this.$route.path);
    },
  },
  created() {
    // this.disableRight();
    this.fetchPageDetail()
    this.$root.$on("setPageTitle", (pageTitle) => {
      this.pageTitle = pageTitle;
      // console.log(pageTitle);
    });

    // let path = "/" + this.$route.path.split("/")[1];
    this.pathnya = this.$route.path;
    // console.log("pathnya", this.pathnya);
    // let index = this._.findIndex(this.items, { link: path });
    // if (index != -1) {
    //   this.pageTitle = this.items[index].title;
    //   this.breadcrumbs.push({
    //     text: this.items[index].title,
    //     disabled: true,
    //     href: path,
    //   });
    // }
  },
  methods: {
    renderIcon(value) {
      switch (value) {
        case "website":
          return "mdi-web";
        case "instagram":
          return "mdi-instagram";
        case "youtube":
          return "mdi-youtube";
        case "email":
          return "mdi-email";
        case "tiktok":
          return "mdi-tiktok";
        case "facebook":
          return "mdi-facebook";
        case "telegram":
          return "mdi-telegram";
        case "whatsapp":
          return "mdi-whatsapp";
        default:
          return "mdi-web";
      }
    },
    toLinkContactUs(type) {
      switch (type) {
        case 'webiste':
          return window.open('https://yec.co.id', '_blank');
        case 'instagram':
          return window.open('https://www.instagram.com/kelasprakerja.yec', '_blank');
        case 'youtube':
          return window.open('https://www.youtube.com/@yecprakerja', '_blank');
        case 'email':
          return 
        case 'tiktok':
          return window.open('https://www.tiktok.com/@prakerjayec', '_blank');
        case 'whatsapp':
          return window.open('https://wa.me/6285335849962', '_blank');
        default:
          break;
      }
    },
    async fetchPageDetail() {
      try {
        const response = await this.axios.get(`/company/v1/public/company-page/${process.env.VUE_APP_ACCESS_KEY}/privacy-policy`)
        
        this.is_status_privacy_policy = response.data.data.is_status

        if(!response.data.data.is_status && this.$route.path == '/privacy') {
          this.$router.push('/')
        }
      } catch (error) {
        console.log(error)
      }
    },
    menuItems() {
      return this.menu;
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
        window.location.reload(true);
      });
    },
    beranda(){
      this.$router.push("/");
    },
    siapKerja() {
      this.$router.push("/siap-kerja");
    },
    signUp(){
      this.$router.push("/auth/registration");
    },
    signIn(){
      this.$router.push("/auth/login");
    },
    prakerja() {
      this.$router.push("/kelas-prakerja");
    },
    toPrivacy() {
      this.$router.push("/privacy");
    },
    // menu() {
    //   this.$router.push("/");
    // },
    updateLocation(item) {
      // this.pageChanged(0, item);
      this.pageTitle = item.title;
    },
    pageChanged(segment, location = null) {
      // console.log(segment);
      // console.log(location);
      this.pathnya = this.$route.path;
      // console.log("pathpage", this.pathnya);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      // window.location.reload(true);
      document.title =
        this.breadcrumbs.map((val) => val.text).join(" -> ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>

<style>
.toolbar-maintenance {
  position: fixed !important;
  margin-top: 64px !important;
  width: 100% !important;
  z-index: 2 !important;
}
</style>
