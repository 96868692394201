<template>
  <div>
    <section
      id="banner"
      :class="$vuetify.breakpoint.name == 'xs' ? 'mx-4' : ''"
    >
      <div class="col-md-10 mx-auto pa-0 pt-5">
        <v-carousel
          cycle
          hide-delimiter-background
          touchless
          :show-arrows="false"
          class="box-slider"
        >
          <v-carousel-item v-for="item in site_data.sliders" :key="item.link" class="">
            <a
              class=""
              :href="item.link"
              target="_blank"
            >
              <img
                :src="item.data.url"
                class="img-banner rounded-lg"
              />
            </a>
          </v-carousel-item>
        </v-carousel>
      </div>
    </section>

    <section
      id="cta-login"
      class="py-5"
      :class="$vuetify.breakpoint.name == 'xs' ? 'mx-4' : ''"
    >
      <div class="col-md-10 mx-auto pa-0 pt-5">
        <div class="bg-cta-login d-flex align-center rounded-lg">
          <v-card-text
            class="d-flex justify-space-between align-center px-15"
            :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column' : ''"
          >
            <div :class="$vuetify.breakpoint.name == 'xs' ? 'mb-2' : ''">
              <h4
                class="white--text"
                :class="
                  $vuetify.breakpoint.name == 'xs'
                    ? 'fs-14 text-center'
                    : 'text-h5'
                "
              >
                Mau redeem kelas Prakerja?
              </h4>
              <div
                class="white--text"
                :class="
                  $vuetify.breakpoint.name == 'xs'
                    ? 'fs-12 text-center'
                    : 'text-h6'
                "
              >
                Yuk Login dulu!
              </div>
            </div>
            <div :class="$vuetify.breakpoint.name == 'xs' ? 'd-flex' : ''">
              <v-btn
                depressed
                x-small
                class="primary--text font-weight-medium mr-4"
                :rounded="$vuetify.breakpoint.name == 'xs'"
                :class="
                  $vuetify.breakpoint.name == 'xs' ? '' : ' px-7 rounded-lg'
                "
                @click="login"
              >
                Login Sekarang
              </v-btn>
              <v-btn
                depressed
                x-small
                color="#f7941e"
                class="white--text font-weight-medium"
                :class="
                  $vuetify.breakpoint.name == 'xs' ? '' : ' px-7 rounded-lg'
                "
                :rounded="$vuetify.breakpoint.name == 'xs'"
                style="border: 1px solid #fff"
                target="_blank"
                href="https://www.youtube.com/watch?v=7R5n6SSf32A"
              >
                Lihat Tutorial
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </div>
    </section>

    <section
      v-if="list.list.length || listPrakerjaPembelajaranMandiri.list.length"
      id="kategori"
    >
      <div v-show="total > 0" class="col-md-10 mx-auto">
        <div class="mb-sm-3 d-flex">
          <p
            class="my-auto font-weight-bold text-capitalize"
            :class="$vuetify.breakpoint.name == 'xs' ? 'fs-18' : 'fs-22'"
          >
            Kelas Populer
          </p>
          <div
            v-show="total === 4"
            class="button-selengkapnya ms-auto text-end"
          >
            <v-btn
              class="px-1 font-weight-bold text-decoration-line"
              text
              color="primary"
              :href="
                $router.resolve({
                  name: 'bidang-studi',
                  query: { is_free: true },
                }).href
              "
            >
              <span class="fs-16 fs-xs-14">Lihat Semua</span>
            </v-btn>
          </div>
        </div>

        <!-- LIST KHUSUS -->
        <div class="row box-deks">
          <div
            v-if="loading"
            class="row mb-3 justify-center"
            style="min-height: 400px"
          >
            <v-skeleton-loader
              v-for="index in 10"
              :key="index"
              class="mx-auto"
              width="210"
              type="card"
            >
            </v-skeleton-loader>
          </div>
          <div
            v-else
            v-for="(item, index) in listKhusus"
            :key="index + 'B'"
            class="col-xl-3 col-md-3 col-sm-6 col-6"
          >
            <v-card class="mx-auto transparent" elevation="0">
              <router-link
                class=""
                :to="{ name: 'detail-kelas', params: { id: item.id } }"
                @click.stop
              >
                <v-img
                  :src="item.program_information.cover.url"
                  class="img-program rounded-lg"
                />
                <v-card-text class="pa-1 banner-class rounded-b-lg">
                  <div
                    class="white--text font-weight-bold px-4 subtitle-2 text-no-wrap"
                  >
                    {{
                      program.class_type == 1
                        ? "Webinar"
                        : "Pembelajaran Mandiri"
                    }}
                  </div>
                </v-card-text>

                <v-card-subtitle class="pa-0 box-content-title">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="black--text font-weight-medium text-dots-2 fs-16 fs-xs-14"
                        >{{ item.title }}</span
                      >
                    </template>
                    <span class="text-subtitle-2">{{ item.title }}</span>
                  </v-tooltip>
                  <span
                    v-if="item.program_information.discount_price != 0"
                    class="fs-14 color-strike"
                    ><s
                      >Rp
                      {{
                        toCurrency(item.program_information.discount_price)
                      }}</s
                    ></span
                  >
                </v-card-subtitle>

                <v-card-text class="pa-0">
                  <h3 class="font-weight-bold black--text">
                    Rp {{ toCurrency(item.program_information.selling_price) }}
                  </h3>
                </v-card-text>
              </router-link>
              <v-card-actions class="pa-0 pt-2">
                <v-btn
                  color="#F60"
                  rounded
                  depressed
                  small
                  class="px-3 fs-14 font-weight-medium btn-beli white--text px-8"
                  @click="getDialog(item.id)"
                >
                  Beli Pelatihan
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>

        <!-- LIST KHUSUS LIMIT -->
        <div class="row box-program mt-0 mt-sm-3">
          <div
            v-if="loading"
            class="row mb-3 justify-center"
            style="min-height: 400px"
          >
            <v-skeleton-loader
              v-for="index in 10"
              :key="index"
              class="mx-auto"
              width="210"
              type="card"
            >
            </v-skeleton-loader>
          </div>
          <div
            v-else
            v-for="(item, index) in listKhususLimit"
            :key="index + 'B'"
            class="col-xl-3 col-md-3 col-sm-6 col-6"
          >
            <v-card class="mx-auto transparent" elevation="0">
              <router-link
                class=""
                :to="{ name: 'detail-kelas', params: { id: item.id } }"
                @click.stop
              >
                <v-img
                  :src="item.program_information.cover.url"
                  class="img-program rounded-lg"
                />
                <v-card-text class="pa-1 banner-class rounded-b-lg">
                  <div
                    class="white--text font-weight-bold px-4 subtitle-2 text-no-wrap"
                  >
                    {{
                      program.class_type == 1
                        ? "Webinar"
                        : "Pembelajaran Mandiri"
                    }}
                  </div>
                </v-card-text>

                <v-card-subtitle class="pa-0 box-content-title">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="black--text font-weight-medium text-dots-2 fs-16 fs-xs-14"
                        >{{ item.title }}</span
                      >
                    </template>
                    <span class="text-subtitle-2">{{ item.title }}</span>
                  </v-tooltip>
                  <span
                    v-if="item.program_information.discount_price != 0"
                    class="fs-14 color-strike"
                    ><s
                      >Rp
                      {{
                        toCurrency(item.program_information.discount_price)
                      }}</s
                    ></span
                  >
                </v-card-subtitle>

                <v-card-text class="pa-0">
                  <h3 class="font-weight-bold black--text">
                    Rp {{ toCurrency(item.program_information.selling_price) }}
                  </h3>
                </v-card-text>
              </router-link>
              <v-card-actions class="pa-0 pt-2">
                <v-btn
                  color="#F60"
                  rounded
                  depressed
                  small
                  class="px-3 fs-14 font-weight-medium btn-beli white--text px-8"
                  @click="getDialog(item.id)"
                >
                  Beli Pelatihan
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>

      <!-- PROGRAM PRAKERJA -->
      <div v-if="list.list.length" class="pt-6 pt-md-3 col-md-10 mx-auto">
        <div class="mb-3 d-flex" style="color: #ee6e18 !important">
          <p
            class="my-auto font-weight-bold"
            :class="$vuetify.breakpoint.name == 'xs' ? 'fs-18' : 'fs-22'"
          >
            <!-- {{ item.title }} -->
            Pelatihan Prakerja Webinar Yang Tersedia
          </p>
          <div class="button-selengkapnya ms-auto text-end">
            <v-btn
              class="px-1 font-weight-bold text-decoration-line"
              text
              color="primary"
              :href="
                $router.resolve({
                  name: 'bidang-studi',
                  query: { class_type: 1, is_free: false },
                }).href
              "
            >
              <img
                :src="require('@/assets/images/icon_find.png')"
                alt="icon find"
                width="30"
                class="mb-1 mr-1"
              />
              <span class="fs-22 fs-xs-14" style="color: #ee6e18 !important"
                >Lihat Semua</span
              >
            </v-btn>
          </div>
        </div>
        <v-card class="mx-auto transparent rounded-lg pa-4" elevation="3" :class="{'rescale': $vuetify.breakpoint.name == 'xs'}">
          <div class="d-flex overflow-auto pa-2" >
            <div
              :class="index + 1 != list.list.length ? 'mr-4' : ''"
              v-for="(program, index) in list.list"
              :key="index"
              style="flex: 1; min-width: 240px"
            >
              <router-link
                class=""
                :to="{ name: 'detail-kelas', params: { id: program.id } }"
                @click.stop
              >
                <v-card
                  class="mx-auto transparent rounded-lg d-flex flex-column"
                  elevation="3"
                  style="height: 315px"
                >
                  <img
                    :src="program.program_information?.cover?.url"
                    :lazy-src="program.program_information?.cover?.url"
                    class="img-program rounded-t-lg"
                  />
                  <v-card-text
                    class="pa-1 banner-class rounded-b-lg"
                    style="
                      filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03))
                        drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
                    "
                  >
                    <div
                      class="white--text font-weight-bold text-no-wrap"
                      :class="
                        $vuetify.breakpoint.name == 'xs'
                          ? 'caption px-1'
                          : 'subtitle-2 px-4'
                      "
                    >
                      {{
                        program.class_type == 1
                          ? "Webinar"
                          : "Pembelajaran Mandiri"
                      }}
                    </div>
                  </v-card-text>

                  <v-card-text class="px-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="black--text font-weight-bold text-dots-2 fs-16 fs-xs-14"
                          >{{ program.title }}</span
                        >
                      </template>
                      <span class="text-subtitle-2">{{ program.title }}</span>
                    </v-tooltip>
                    <h3 class="font-weight-black primary--text">
                      Rp{{
                        toCurrency(program.program_information?.selling_price)
                      }}
                    </h3>
                  </v-card-text>
                  <v-card-actions
                    class="pt-2 d-flex justify-center align-center mt-auto"
                  >
                    <v-btn
                      color="#F95712"
                      depressed
                      rounded
                      small
                      class="fs-14 font-weight-medium btn-beli white--text px-8"
                      @click="getDialog(program.id)"
                    >
                      Beli Pelatihan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </router-link>
            </div>
          </div>
        </v-card>
      </div>

      <!-- PEMBELAJARAN MANDIRI -->
      <div
        v-if="listPrakerjaPembelajaranMandiri.list.length"
        class="pt-6 pt-md-3 col-md-10 mx-auto"
      >
        <div class="mb-3 d-flex" style="color: #ee6e18 !important">
          <p
            class="my-auto font-weight-bold"
            :class="$vuetify.breakpoint.name == 'xs' ? 'fs-18' : 'fs-22'"
          >
            <!-- {{ item.title }} -->
            Pelatihan Prakerja Pembelajaran Mandiri Yang Tersedia
          </p>
          <div class="button-selengkapnya ms-auto text-end">
            <v-btn
              class="px-1 font-weight-bold text-decoration-line"
              text
              color="primary"
              :href="
                $router.resolve({
                  name: 'bidang-studi',
                  query: { class_type: 2, is_free: false },
                }).href
              "
            >
              <img
                :src="require('@/assets/images/icon_find.png')"
                alt="icon find"
                width="30"
                class="mb-1 mr-1"
              />
              <span class="fs-22 fs-xs-14" style="color: #ee6e18 !important"
                >Lihat Semua</span
              >
            </v-btn>
          </div>
        </div>
        <v-card class="mx-auto transparent rounded-lg pa-4" elevation="3" :class="{'rescale': $vuetify.breakpoint.name == 'xs'}">
          <div class="d-flex overflow-auto pa-2">
            <div
              :class="
                index + 1 != listPrakerjaPembelajaranMandiri.list.length
                  ? 'mr-4'
                  : ''
              "
              v-for="(program, index) in listPrakerjaPembelajaranMandiri.list"
              :key="index"
              style="flex: 1; min-width: 240px"
            >
              <router-link
                class=""
                :to="{ name: 'detail-kelas', params: { id: program.id } }"
                @click.stop
              >
                <v-card
                  class="mx-auto transparent rounded-lg d-flex flex-column"
                  elevation="3"
                  style="height: 315px"
                >
                  <img
                    :src="program.program_information?.cover?.url"
                    :lazy-src="program.program_information?.cover?.url"
                    class="img-program rounded-t-lg"
                  />
                  <v-card-text
                    class="pa-1 banner-class rounded-b-lg"
                    style="
                      filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03))
                        drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
                    "
                  >
                    <div
                      class="white--text font-weight-bold text-no-wrap"
                      :class="
                        $vuetify.breakpoint.name == 'xs'
                          ? 'caption px-1'
                          : 'subtitle-2 px-4'
                      "
                    >
                      {{
                        program.class_type == 1
                          ? "Webinar"
                          : "Pembelajaran Mandiri"
                      }}
                    </div>
                  </v-card-text>

                  <v-card-text class="px-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="black--text font-weight-bold text-dots-2 fs-16 fs-xs-14"
                          >{{ program.title }}</span
                        >
                      </template>
                      <span class="text-subtitle-2">{{ program.title }}</span>
                    </v-tooltip>
                    <h3 class="font-weight-black primary--text">
                      Rp{{
                        toCurrency(program.program_information?.selling_price)
                      }}
                    </h3>
                  </v-card-text>
                  <v-card-actions
                    class="pt-2 d-flex justify-center align-center mt-auto"
                  >
                    <v-btn
                      color="#F95712"
                      depressed
                      rounded
                      small
                      class="fs-14 font-weight-medium btn-beli white--text px-8"
                      @click="getDialog(program.id)"
                    >
                      Beli Pelatihan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </router-link>
            </div>
          </div>
        </v-card>
      </div>
    </section>

    <section id="kategori" class="py-5">
      <div class="pt-6 pt-md-3 col-md-10 mx-auto px-3">
        <div class="mb-3 d-flex" style="color: #ee6e18 !important">
          <p
            class="my-auto font-weight-bold"
            :class="$vuetify.breakpoint.name == 'xs' ? 'fs-18' : 'fs-22'"
          >
            Beli Pelatihan di Platform Digital Pilihanmu
          </p>
        </div>

        <v-row>
          <v-col cols="4" md="4" lg="4">
            <v-card
              class="rounded-lg py-0"
              :class="$vuetify.breakpoint.name == 'xs' ? 'px-1' : 'px-4'"
              color="#EFF2F7"
              elevation="3"
              @click="toDigitalPlatform('bukalapak')"
            >
              <img
                src="@/assets/images/logo_bukalapak.svg"
                class="img-digital-platform rounded-t-lg"
              />
            </v-card>
          </v-col>
          <v-col cols="4" md="4" lg="4">
            <v-card
              class="rounded-lg py-0"
              :class="$vuetify.breakpoint.name == 'xs' ? 'px-1' : 'px-4'"
              color="#EFF2F7"
              elevation="3"
              @click="toDigitalPlatform('tokopedia')"
            >
              <img
                src="@/assets/images/logo_tokopedia.svg"
                class="img-digital-platform rounded-t-lg"
              />
            </v-card>
          </v-col>
          <v-col cols="4" md="4" lg="4">
            <v-card
              class="rounded-lg py-0"
              :class="$vuetify.breakpoint.name == 'xs' ? 'px-1' : 'px-4'"
              color="#EFF2F7"
              elevation="3"
              @click="toDigitalPlatform('kariermu')"
            >
              <img
                src="@/assets/images/logo_kariermu.svg"
                class="img-digital-platform rounded-t-lg"
              />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </section>

    <v-dialog v-model="dialog" width="500" content-class="rounded-lg">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title class="fs-18 font-weight-medium"
            >Pilih Platform Pembelian</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              icon
              @click="dialog = false"
              class="ml-auto"
              color="primary"
            >
              <v-icon>ri-close-fill</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pb-0 pt-2">
          <div class="col-md-8 text-center mx-auto py-3">
            <div v-for="(item, index) in link" :key="'A' + index">
              <v-btn
                depressed
                class="rounded-xl mb-2 white--text"
                color="primary"
                :href="item.url"
                target="_blank"
                block
                >{{ item.title }}</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import resources from "@/store/resources";
import api from "@/models/home";
import { mapState } from 'vuex';

export default {
  name: "public-beranda",
  data: () => ({
    featured: {
      webinar: {},
      learning: {},
    },
    resources: [],
    list: [],
    listPrakerjaPembelajaranMandiri: [],
    listKhusus: [],
    listKhususLimit: [],
    test: [
      { title: "tokopedia", url: "https://" },
      { title: "shopee", url: "https://" },
    ],
    dialog: false,
    loading: false,
    total: null,

    link: [],
    offset: true,
  }),
  computed: {
    ...mapState({
      site_data: state => state.company.site_data
    }),
  },
  created() {
    this.resources = resources;
  },
  mounted() {
    this.getList();
    this.getListPrakerjaPembelajaranMandiri();

    // this.getListKhusus();
    // this.getListKhususLimit();

    // console.log(this.$store.getters.isLoggedIn);
  },
  methods: {
    getList() {
      api
        .getFeaturedProgram({
          limit: 5,
          class_type: 1,
          show_in_dashboard: true,
        })
        .then((response) => {
          this.content = true;
          this.loadContent = false;
          let res = response.data.data;
          this.submitting = false;
          this.list = res;
        });
    },
    getListPrakerjaPembelajaranMandiri() {
      api
        .getFeaturedProgram({
          limit: 5,
          class_type: 2,
          show_in_dashboard: true,
        })
        .then((response) => {
          this.content = true;
          this.loadContent = false;
          let res = response.data.data;
          this.submitting = false;
          this.listPrakerjaPembelajaranMandiri = res;
        });
    },
    getListKhusus() {
      this.axios
        .get(
          `users/v1/public/program/list?status=publish&page=1&limit=4&sort=_id&dir=desc&is_free=true&ids=643661ee1639c35dc665287c`,
          {
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
          }
        )
        .then((response) => {
          let res = response.data.data;
          this.listKhusus = res.list;
          this.total = res.total;
        });
    },
    getListKhususLimit() {
      this.axios
        .get(
          `users/v1/public/program/list?status=publish&page=1&limit=2&sort=_id&dir=desc&is_free=true&ids=643661ee1639c35dc665287c`,
          {
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
          }
        )

        .then((response) => {
          let res = response.data.data;
          this.listKhususLimit = res.list;
          this.total = res.total;
        });
    },
    getDialog(id) {
      this.axios
        .get(`/users/v1/public/program/detail/${id}`)
        .then((response) => {
          // let res = response.data.data;
          this.link = response.data.data.program_information.selling_link;
          this.dialog = true;
        });
    },
    login() {
      this.$router.push("/auth/login");
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      // var formatter = new Intl.NumberFormat('en-IN', {
      var formatter = new Intl.NumberFormat("de-DE", {
        // style: 'currency',
        // currency: 'IDR',
        // minimumFractionDigits: 0
      });
      return formatter.format(value);
    },
    toDigitalPlatform(type_platform) {
      switch (type_platform) {
        case "bukalapak":
          return window.open(
            "https://www.bukalapak.com/kartu-prakerja/lembaga/yeccoid-3804?source=tab-merchant",
            "_blank"
          );
        case "tokopedia":
          return window.open(
            "https://www.tokopedia.com/kartu-prakerja/partner/yec-co-id",
            "_blank"
          );
        case "kariermu":
          return window.open("https://app.karier.mu/mitra/yec-co-id", "_blank");
        default:
          break;
      }
    },
  },
};
</script>

<style>
.box-slider {
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto !important;
}

#banner .v-carousel__controls > .v-item-group {
  position: absolute;
  /*left: 11% !important;*/
}

#banner .v-carousel__controls__item {
  margin: 0;
}

#banner .v-image {
  /*max-height: 360px!important;*/
  height: auto !important;
}

#banner .v-carousel__controls {
  bottom: 5% !important;
}

.img-banner {
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto !important;

  /*object-fit: contain;*/
}

.bg-cta-login {
  background-image: url("../../assets/images/bg-login.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
  /* opacity: 0.80; */
  background-size: cover;
}

.img-program {
  width: 100%;
  height: 130px;
  max-height: 130px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.img-digital-platform {
  width: 100%;
  height: 100px;
  object-fit: fill;
  margin-top: 10px;
}
.img-kategori {
  width: 100%;
}

/*@media (max-width: 768px) {
  #banner .v-image{
    height: auto!important;
  }
}*/
@media (max-width: 425px) {
  .bg-cta-login {
    background-image: url("../../assets/images/bg-login-mobile.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    /* opacity: 0.80; */
    background-size: cover;
  }
  .img-program {
    height: 130px;
    object-fit: cover;
  }

  .img-digital-platform {
    height: 30px;
    object-fit: fill;
    margin-top: 10px;
  }
  .box-slider {
    height: auto !important;
  }

  #banner .v-btn--icon.v-size--small {
    height: 15px;
    width: 15px;
  }

  #banner .v-btn__content i {
    font-size: 12px !important;
  }

  #banner .v-carousel__controls {
    bottom: -10px;
  }

  .v-carousel__item {
    height: auto !important;
  }

  .img-banner {
    height: auto !important;
  }

  .bg-cta-login {
    background-position: right !important;
    height: auto !important;
    width: 100%;
  }

  #cta-login .headline {
    font-size: 16px !important;
  }

  .btn-beli {
    font-size: 12px !important;
  }
}

@media (max-width: 375px) {
}
.rescale {
  transform: scale(.4);
  transform-origin: top left;
  width: calc(100% / 0.4);
  max-width: initial;
  margin-bottom: calc(-6 / 10 * 100%);
}
</style>